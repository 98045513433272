import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileRAYANHAMO from './RAYAN ALHAMO.png';
function Page44_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
RAYANHAMO:2007,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">RAYAN HAMO </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.RAYANHAMO} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileRAYANHAMO}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我在七歲時離開了敘利亞，只在敘利亞讀過一年級。當戰爭爆發時，我們決定在2014年初逃往土耳其。
慈濟在我們的生活中扮演了重要的角色，她們接納了我們到她們的學校，並透過卡片提供了持續的援助，至今依然如此。
當我聽到證嚴法師的話語時，我的心中充滿了激動與感激。我非常迫切地希望能把她的話語表達在我親手畫的畫作上。然而，我發現這只是微不足道的回報，無法與證嚴法師所給予我們的支持相比，無論我們做什麼，都無法完全回報她們對我們的無私奉獻。我衷心希望將來能夠回報這份恩情。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
خرجت من سوريا في عمر 7 سنوات، درست الصف الأول في سوريا فقط، عندما بدأت الحرب قررنا اللجوء إلى تركيا في بداية عام 2014. 
وكانت (لمؤسسة تسجي) دور كبير في حياتنا، فهي التي استقبلتنا في مدرستها، وقدمت لنا المساعدة عن طريق كروت البيم وما زالت إلى الأن. 
عندما سمعت كلمات (الماستر) كانت مشاعري يملؤوها الحماس، كنت متحمسة جداً كي أعبر عن كلماتها في لوحة رسمتها يداي، ولكنني رأيته شيئاً بسيطاً أمام ما قدمته لنا (الماستر) فمهما فعلنا لا نقدر أن نكافئكم على الأشياء التي قدمتموها لنا دون التوقف، فأتمنى أن أرد لها الجميل في المستقبل.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page44_b;
