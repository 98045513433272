import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./015.png";
import Page15_b from "./Page15_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page15() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
對人要寬心，
<br />講話要細心。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Duì rén yào kuānxīn，
<br />jiǎnghuà yào xìxīn.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
كن متسامحاً مع الآخرين وكن حذراً في حديثك.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
İnsanlara karşı hoşgörülü ol，konuşmalarında da dikkatli ol.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/vIcHIYh2sYs"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/542zcQAHRWw"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/oqCdILB6vd8"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
طوبى لمن شغله عيبه عن عيوب الناس. _ مثل عربي
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
لا إنسان كامل في الحياة، فلكل حسناته وسيئاته، والعاقل الذي يبذل قصارى جهده لمعالجة أخطائه وعثراته، ولا يبالي ولا يهتم لأخطاء غيره، ففي عيوب نفسه ما يكفي لانشغاله بتصويبها، فتتبع زلات غيرك لن يمحو زلاتك، بل يزيدها سوءا، فنفسك أولى بالاهتمام والعناية.
</p>
 <h5 className="turkish-text mt-4">
Ne mutlu o insana ki kendi kusurlarıyla meşgul olup başkalarının kusurlarını görmez. _ Arap atasözü
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
反省自己而不是尋找他人的過錯。
(阿拉伯諺語)
</h5>
<p className="pinyin-text">
Fǎnxǐng zìjǐ ér bùshì xúnzhǎo tārén de guòcuò
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page15_b />
</section>
);
}
export default Page15;



