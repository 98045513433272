import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileSABAHALNOURMOSELLI from './SABAH ALNOUR MOSELLI.png';
function Page13_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
SABAHALNOURMOSELLI:2011,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">SABAH ALNOUR MOSELLI</strong>
</h1>
<h1 className="heading-name">
My age now: {ages.SABAHALNOURMOSELLI} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileSABAHALNOURMOSELLI}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
2015年，我們乘船來到土耳其，途中面臨了無數困難。抵達土耳其後，困難並未結束，我們還面臨著新的挑戰，其中之一便是語言障礙。當時我才五歲，對這裡的語言一無所知。

我們尋求慈濟的幫助，他們如同我們的第二個家庭般接納了我們，並開始提供物質援助，如發放BIM購物卡。我進入了滿納海學校，在那裡學習了阿拉伯語和土耳其語。

我創作了一幅畫，因為它表達了對證嚴法師的敬意與尊重，也傳達了那份溫暖人心的感情。這份感情來自我們對他人的關懷，這正是我們從生活中學到的心地善良和純潔的精神，因為生活需要這樣的情感來支撐。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
وصلت إلى تركيا في عام 2015 عن طريق البحر، واجهنا صعوبات كثيرة أثناء الطريق. 
وعندما وصلنا إلى تركيا لم تنته الصعوبات بل واجهنا صعوبات جديدة، منها اللغة فقد دخلت وكان عمري ٥ سنوات لا أعرف من هذه اللغة شيئاً.
لجأنا إلى (تسجي) التي احتضنتنا وكأنها عائلتنا الثانية، وبدأت بتوزيع المساعدات المادية مثل كروت البيم، ودخلت (مدرسة المناهل) التي تعلمت فيها اللغة العربية واللغة التركية.
رسمت لوحة لأنها تعبر عن كلام الزعيمة بلطف واحترام، والمشاعر التي دفعتني إلى ذلك الشعور بالآخرين التي تعلمنا طيبة القلب والروح؛ لأن الحياة تتطلب منا هذه المشاعر.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page13_b;
