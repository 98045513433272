import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileCEDRASHABBAN from './SEDRA SHABBAN.png';
function Page2_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
CEDRASHABBAN:2006,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">CEDRA SHABBAN </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.CEDRASHABBAN} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileCEDRASHABBAN}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
2013年，我們逃離戰禍來到土耳其，但苦難尚未結束，工作開始發生困難，生活和教育也困難重重。
慈濟基金會幫助了我們，並提供了一所學校可以學習，我們在其中學到價值觀和道德。
證嚴法師 給我們的東西有一種愛的感覺，加上她的話很美很現實，我在生活中遵循這個規則，所以我決定把這句話畫在一張紙上。
 「真誠地工作，用快樂的心接受結果，你越努力，你的得到的就會越多。”
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
عام 2013، وصلت الى تركيا عبر الحدود. كان العمل صعب جداً وكانت الحياة قاسية جداً.وصلنا تركيا عام 2013 هاربين من ويلات الحرب، لكن المعاناة لم تنته بعد، وبدأت الصعوبة .. كان توفير العمل صعباً، وكذلك الوضع المعيشي والتعليم. فقامت (مؤسسة تسجي) بمساعدتنا وتأمين مدرسة نتعلم منها ونستفيد من القيم والأخلاق التي تعلمنا فيها.
أشعر اتجاه (الماستر) بشعور الحب لما قدمته لنا، بالإضافة إلى أن كلامها جميل وواقعي، ولأنني متّبعة هذه القاعدة في حياتي، فلذلك قررت أن أرسم هذه العبارة في لوحة: " إعمل بإخلاص وتقبل النتائج بقلب سعيد، وكلما بذلت جهد أكثر زادت مهاراتك اكثر"..
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page2_b;
