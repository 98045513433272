import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileMALAKALLTFO from './MALAK ALLTFO.png';
function Page25_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
MALAKALLTFO:2009,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">MALAK ALLTFO</strong>
</h1>
<h1 className="heading-name">
My age now: {ages.MALAKALLTFO} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileMALAKALLTFO}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
MALAK ALLTFO
在 2014 年，我只有五歲，敘利亞的戰爭讓我們不得不背井離鄉，逃往土耳其。在我們抵達土耳其後，父親為了生計，不得不離開我們，前往其他地方，而我們則留在伊斯坦布爾。在這段艱難的時期，慈濟基金會像一道曙光，成為了我們的依靠。  
慈濟基金會不僅為我和我的兄弟姐妹們提供了學習的機會，還幫助我的姐姐們實現了她們的夢想，順利進入大學。那份支持和關懷，就像溫暖的春風，撫慰著我們每一顆不安的心。  
證嚴法師的話語充滿了意義，像一盞明燈，指引著我們前進的方向，並激勵著我們不斷努力。畫畫或許是一種簡單的方式，卻能夠生動地傳達深刻的想法。將這些想法具現化成一幅畫，是一個極佳的創意，因為它讓每一筆色彩都充滿了力量，傳遞著堅持與希望的訊息。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
في عام 2014 عندما كنت في الخامسة من عمري  لجأنا إلى تركيا بسبب الحرب القائمة في سوريا ، وبعد أن جئنا إلى تركيا هاجر أبي وتركنا في إسطنبول فكانت (تسجي) خير معين لنا في تأمين الدراسة لي ولإخوتي وساعدتنا في دخول أخواتي إلى الجامعة.
عبارات (الماستر) معبرة و تدفع للمثابرة، ريما كان الرسم طريقة سهلة لتوصيل الفكرة و تجسيدها بلوحة كانت فكرة رائعة أيضا . 
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page25_b;
