import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./MasterChengYen.png"; 
import Biography_b from "./Biography_b";
import Tilt from "react-parallax-tilt";
import AOS from "aos";

function Biography() {
  useEffect(() => {
    AOS.init({ duration: 1200, once: false });
  }, []);

  return (
    <section>
      {/* Main Biography Section */}
      <Container fluid className="home-about-section" id="about">
        <Container>
          <Row className="justify-content-center">
            <Col md={6} sm={10} className="mb-4" data-aos="fade-right">
              <Tilt>
                <img
                  src={myImg}
                  className="img-fluid"
                  alt="Master Cheng Yen"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                    margin: '0 auto',
                    display: 'block',
                    marginTop: '50px'  
                  }}
                />
              </Tilt>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* Section Title Below Image */}
      <Container fluid className="home-section mb-4">
        <h1 id="about-section" className="project-heading text-center" data-aos="flip-down">
          <strong className="purple">傳記</strong> | <strong className="purple">السيرة الذاتية</strong> | <strong className="purple">Biyografi</strong>
        </h1>
      </Container>

      {/* Text Section with Fieldset for each language */}
      <Container fluid className="Story-section">
        <Container>
          <Row className="justify-content-center">
            {/* Chinese Fieldset */}
            <Col md={4} xs={12} data-aos="fade-right">
              <fieldset className="border p-3">
                <p className="chinese-text">
                  
                "證嚴上人一九三七年生於臺灣臺中清水，一九六三年皈依上印下順導師，服膺「為佛教、為眾生」之慈示，感於花東地區貧窮落後、醫療匱乏，於一九六六年五月十四日，在花蓮佳民村普明寺成立「佛教克難慈濟功德會」，展開「竹筒歲月」，向大眾募心募款，勸勉日存五毛錢，開始從事慈善教富濟貧工作。"
                經過此連貫思惟，四大志業想法萌發心中，於一九六九年五月十日，在康樂村啟用靜思精舍，辦理寺廟登記時，即註明建寺目的是依四大志業「慈善、醫療、教育、文化﹙人文﹚」前後次序落實於社會。
                </p>
              </fieldset>
            </Col>

            {/* Arabic Fieldset */}
            <Col md={4} xs={12} data-aos="fade-left">
              <fieldset className="border p-3">
                <p className="arabic-text">
                  "ولدت الماستر تشنغ يان في عام 1937 في تشينغشوي، تايتشونغ، تايوان.
                  أصبحت تلميذة للمعلم إن شون في عام 1963 التزمت بمبادئه “من أجل حياة أفضل، وتقديم ما يمكن لتحسين حياة الناس تأثرت بحال البؤساء الذين يعانون الفقر والتخلف في منطقة هوالينغ وافتقارهم إلى الرعاية الطبية."
                  "في 14 مايو 1966، أسست “جمعية الأعمال الخيرية تسجي” في قرية جيا مين، هوالين، وبدأت حملة “سنوات البامبو”، حيث كانت تجمع التبرعات من الناس، وتشجعهم على التبرع بخمسين سنتًا يوميًا لبدء الأعمال الخيرية لمساعدة الفقراء."

                </p>
              </fieldset>
            </Col>

            {/* Turkish Fieldset */}
            <Col md={4} xs={12} data-aos="fade-right">
              <fieldset className="border p-3">
                <p className="pinyin-text">
                  "Master Cheng Yen, 1937 yılında Tayvan’ın Taichung eyaletine bağlı Qingshui’de doğdu. 
                  1963 yılında En Shun öğretmeninin öğrencisi oldu ve onun “Daha iyi bir yaşam için ne yapılabilir, insan hayatını nasıl iyileştirebiliriz?” ilkelerine sadık kaldı."
                  "14 Mayıs 1966’da Hualien’in Jiamei köyünde “Tzu Chi Yardımseverlik Vakfı”nı kurdu ve “Bambu Yılları” kampanyasını başlattı. Fakirlere yardım etmek için bağış topladı."

                </p>
              </fieldset>
            </Col>
          </Row>

     

          {/* Continue with more blocks similarly */}
        </Container>
      </Container>

      <Biography_b />
    </section>
  );
}

export default Biography;
