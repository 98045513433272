import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileSENAHABOUSH from './SENA HABUS.png';
import profileRAYANHAMO from './RAYAN ALHAMO.png';
import profileYARAKAHWATIYE from './YARA KAHWATIYE.png';
import profileCEDRASALAME from './CEDRA SALAME .png';
function Page30_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
SENAHABOUSH:2010,
RAYANHAMO:2007,
YARAKAHWATIYE:2008,
CEDRASALAME:2006,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">SENA HABOUSH</strong>
</h1>
<h1 className="heading-name">
My age now: {ages.SENAHABOUSH} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileSENAHABOUSH}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
在敘利亞家園遭到轟炸後，我與家人搬到了土耳其。
我在戰爭開始就開始了從敘利亞到土耳其的旅程，我們開始了兩年沒有受過教育的新生活，日子充滿了挑戰。 最後，我到達了伊斯坦堡的滿納海學校。 
這是一個提供安全的地方。 
感謝  慈濟基金會和 滿納海學校的幫助，我得以繼續學業並得到照顧。
我的畫作反映了靈活性的價值觀，體現了我學到的智慧，他關於寬容和支持他人的重要價值觀
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
بعد فقدان منزلي في سوريا وتعرضه للقصف، انتقلت مع عائلتي إلى تركيا.
بدأت رحلتي من سوريا إلى تركيا خلال الحرب، بدأنا حياة جديدة بدون تعليم لمدة سنتين، وكانت الأيام مليئة بالتحديات. وفي النهاية، وصلت إلى مدرسة المناهل في إسطنبول. فكانت مكانًا يوفر الأمان والدعم. بفضل مساعدةجمعية تسجي ومدرسة المناهل، تمكنت من مواصلة تعليمي والحصول على الاهتمام والرعاية. 
لوحتي تعكس قيم التقبل والمرونة، مجسدة حكمة تعلمتها عن قيمة التسامح وأهمية مساندة الآخرين.
أشعر بامتنان عميق للزعيمة التي ألهمتني، ولأني أحب الرسم كثيرًا، قررت أن أعبر عن كلماتها عبر الفن، ليس فقط لإظهار امتناني، ولكن لكي تبقى هذه العبارات محفورة في أذهان الجميع بطريقة جميلة ومؤثرة.
</p>
</Col>
</Row>
 </Container>
 </Container>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">RAYAN HAMO </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.RAYANHAMO} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileRAYANHAMO}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我在七歲時離開了敘利亞，只在敘利亞讀過一年級。當戰爭爆發時，我們決定在2014年初逃往土耳其。
慈濟在我們的生活中扮演了重要的角色，她們接納了我們到她們的學校，並透過卡片提供了持續的援助，至今依然如此。
當我聽到證嚴法師的話語時，我的心中充滿了激動與感激。我非常迫切地希望能把她的話語表達在我親手畫的畫作上。然而，我發現這只是微不足道的回報，無法與證嚴法師所給予我們的支持相比，無論我們做什麼，都無法完全回報她們對我們的無私奉獻。我衷心希望將來能夠回報這份恩情。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
خرجت من سوريا في عمر 7 سنوات، درست الصف الأول في سوريا فقط، عندما بدأت الحرب قررنا اللجوء إلى تركيا في بداية عام 2014. 
وكانت (لمؤسسة تسجي) دور كبير في حياتنا، فهي التي استقبلتنا في مدرستها، وقدمت لنا المساعدة عن طريق كروت البيم وما زالت إلى الأن. 
عندما سمعت كلمات (الماستر) كانت مشاعري يملؤوها الحماس، كنت متحمسة جداً كي أعبر عن كلماتها في لوحة رسمتها يداي، ولكنني رأيته شيئاً بسيطاً أمام ما قدمته لنا (الماستر) فمهما فعلنا لا نقدر أن نكافئكم على الأشياء التي قدمتموها لنا دون التوقف، فأتمنى أن أرد لها الجميل في المستقبل.
</p>
</Col>
</Row>
 </Container>
 </Container>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">YARA KAHWATIYE </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.YARAKAHWATIYE} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileYARAKAHWATIYE}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
在這段艱難而漫長的旅程中，我的父親比我們先一步出發。
他甚至在渡河進入土耳其時掉進了河裡。
兩年後，我們也來到了土耳其，經歷了一次將近兩個半月的艱辛旅程。
到達土耳其後，我們暫時住在父親一個朋友家裡。然後，我們找到了一個原本是垃圾場和流浪貓棲息地的地下室。我們清理了垃圾，把它打掃乾淨，並在裡面住了六個月。那時正值冬季，每次下雨，雨水都會淹滿整個房子。
我的母親在慈濟基金會的診所裡擔任護士和志工，而我的父親則從事木工工作，直到我們的生活條件稍有改善，能夠搬到一個更好的住所。
當滿納海學校開辦時，我從五年級就開始在那裡學習，直到現在。
我從慈濟基金會學到了很多東西，因此和我的妹妹一起在那裡做志工
證嚴法師的靜思語體現了每個人心中都應該有人性，無論年齡大小都應該存在於每一個人的心中。
靜思語讓我想起了我們伊斯蘭教中的類似教誨和格言，這也是我將這些話轉化為一幅充滿生機的畫作的原因。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
سبقنا والدي في رحلة اللجوء المتعبة والصعبة حتى أنه سقط في النهر أثناء عبوره إلى تركيا، ثم أتينا إلى تركيا بعده بسنتين في رحلة صعبة وطويلة استغرقت ما يقارب من الشهرين والنصف.
عندما وصلنا تركيا أقمنا عند صديق أبي فترة قصيرة، ثم وجدنا قبواً كان مكباً للقمامة ومأوى للقطط، أفرغنا القمامة ونظفناه وبقينا فيه مدة 6 أشهر، كانت الوقت شتاءً، وكان ماء المطر يملأ البيت عندما يهطل في كل مرة. 
عملت أمي ممرضة ومتطوعة في مستوصف (تسجي) وعمل أبي نجارًا إلى أن تحسن وضعنا قليلاً واستطعنا أن ننتقل إلى منزل أفضل. 
وعندما تم افتتاح (مدرسة المناهل) تم تسجيلي فيها من الصف الخامس وحتى الآن. 
تعلمت من (مؤسسة تسجي) الكثير فلذلك تطوعت مع أختي فيها. 
كلام (الماستر) دليل على الإنسانية التي يجب أن تكون في قلوب الجميع، في قلب كل صغير وكبير، ولأنها تذكرني بأقوال وحكم ديننا الإسلامي المشابهة له، هذا الذي كان سببا لأنقل كلامها إلى لوحة تنبض بالحياة..
</p>
</Col>
</Row>
 </Container>
 </Container>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">CEDRA SALAME </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.CEDRASALAME} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileCEDRASALAME}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
莎德拉. 沙萊即
我和我的兄弟們從敘利亞來到土耳其，通過巴布薩拉瑪關口，那時我只有八歲。 
我在工廠工作了一整年，我的父母留在了敘利亞。當我的母親來到土耳其時，她幫我在滿納海學校註冊。
當我得知自己將上學而不是工作時，我感到非常高興。
滿納海學校給了我家庭補助，讓我不用到工廠工作
我想回報這份恩情，並在我的畫作中表達我對證嚴法師為成千上萬的難民所做的一切的感激之情，這雖然只是一件小事，但它的意義非常大。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
قدمت مع أخوتي من سورية إلى تركيا عبر معبر باب السلامة، كنت عمري حينها لم يتجاوز الثماني سنوات. 
عملت في الورشة لمدة سنة كاملة، بقي والداي في سوريا، لما أتت أمي إلى تركيا سجلتني في (مدرسة المناهل) وكانت فرحتي كبيرة عندما علمت أنني سأدرس وأترك العمل.
قدمت لي (مدرسة المناهل) تعويضاً عن راتبي الذي كنت أتقاضاه في العمل.
أردت أن أرد الجميل وأعبر في رسمتي عن امتناني لما فعلت (الماستر) من أجل آلاف اللاجئين، وهو يعتبر شيئاً بسيطاً، لكن دوره المعنوي كان كبيراً..
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page30_b;
