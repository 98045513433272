import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileSARAKELLAWI from './SARA KELLAWI.png';
function Page38_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
SARAKELLAWI:2005,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">SARA KELLAWI </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.SARAKELLAWI} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileSARAKELLAWI}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我的兄弟和父親先於我們前往伊斯坦堡工作後，後來我與妹妹和母親搬到了土耳其。 
我們在這裡住了 8 年，我和妹妹開始在臨時土耳其學校學習，然後𨍭到 滿納海學校。 
在那裡，我們獲得了經濟和精神上的支持，並得到打工學生家庭補助計劃，這使我們能夠安心學習。 
我選擇這幅畫作來表達孝敬父母的價值，我從上人那裡了解到家庭支持的重要性以及父親和母親如何成為靈感和支持的來源。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
انتقلت إلى تركيا مع أختي وأمي بعد أن سبقنا إخوتي وأبي إلى إسطنبول للعمل. منذ 8 سنوات نعيش هنا، وقد بدأت أنا وأختي بالدراسة في المدارس التركية المؤقتة قبل الانتقال إلى مدرسة المناهل. هناك، تلقينا دعمًا ماديًا ومعنويًا واستفدنا من برنامج الطلاب العاملين الذي سمح لنا بالتفرغ للدراسة. اخترت لوحتي لتعبر عن قيمة بر الوالدين، فقد تعلمت من الزعيمة أهمية الدعم الأسري وكيف يمكن أن يكون الأب والأم مصدر إلهام ودعم.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page38_b;
