import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileABDULSALAMTEMIR from './ABDULSALAM TEMIR.png';
import profileRADWANNUAIMI from './RADWAN NUAIMI.png';
function Page8_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
ABDULSALAMTEMIR:2008,
RADWANNUAIMI:2008,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">ABDULSALAM TEMIR</strong>
</h1>
<h1 className="heading-name">
My age now: {ages.ABDULSALAMTEMIR} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileABDULSALAMTEMIR}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
在敘利亞戰爭開始後，我們被迫逃離家園，通過非法途徑逃亡到土耳其。
那段旅程極為艱辛，我們的生活條件非常糟糕，我無法上學，被迫在極其惡劣的環境下工作。
有一天，我偶然從鄰居那裡了解到滿納海學校，這讓我有機會成為這所學校的學生，並從一年級開始在這裡學習。
我選擇了上人的這些話，因為它們與我們的宗教教義相符，即使是一個殘疾人，自己動手勞動比依賴他人和乞討更好。
在這段充滿艱辛和挑戰的旅途中，滿納海學校給了我希望和未來的機會。從一開始的絕望，到後來在學校裡找到的一絲光明，這所學校不僅改變了我的生活，也讓我看到了人性的光輝。
上人的教誨讓我深受感動，即使身處逆境，我們也應該努力自立，不依賴他人，這份智慧和力量將一直引導著我，幫助我面對未來的挑戰。
我對這所學校和慈濟基金會心存無盡的感激，這份愛和關懷讓我的生命充滿了希望和溫暖。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
بعد بدء الحرب التي مرت بها سوريا، اضطررنا للهروب من الحرب واللجوء إلى تركيا عبر طرق غير قانونية، وكانت تلك الرحلة صعبة للغاية. عشنا ظروفاً سيئة جدًا، حيث لم أكن أستطيع الدراسة واضطررت للعمل في ظل ظروف  معيشية سيئة جدًا.
سنحت لي فرصة لأصبح طالبًا في  (مدرسة المناهل) التي تعرفت عليها بالصدفة من جيراننا، وبدأت الدراسة فيها من الصف الأول.
اخترت هذه الكلمات من كلمات (الماستر) لأنها تتوافق مع ديننا فإن العمل باليد حتى لو كان الشخص معاقًا، أفضل من الاعتماد على الآخرين والتسول.
</p>
</Col>
</Row>
 </Container>
 </Container>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">RADWAN NUAIMI </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.RADWANNUAIMI} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileRADWANNUAIMI}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我是2014年到達土耳其的，這是一段非常艱難的旅程。 
我們來到土耳其是為了躲避戰爭和破壞。
我們穿過了一片很大的森林。 
然後我們到達了伊斯坦堡，也就是我們現在所在的地方
當我們到達伊斯坦堡時，生活比我們想像的還要困難。
這導致許多孩子不得不去工作來幫助父母。
我在工廠待了兩年，直到 慈濟基金會給我提供了 滿納海學校 的獎學金，這是我學習之路的開始。
我透過拜訪慈濟基金會認識了她，他們給了我們食物援助，然後他們問我是否在學習，我的回答是沒有，然後他們擁抱了我並實現了我的夢想。
我參與畫了這幅畫，這非常快樂、有趣、有有用的感覺，因為它蘊含著影響心靈、點燃慈悲之情的深刻意義，而最重要的感覺就是對最後兩個人的感覺。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
وصلت عام 2014 وكانت رحلة شاقة جدا. قدمنا الى تركيا هربا من الحرب والدمار وصلنا الى الحدود وكان الظلام دامس في تلك الليلة وكان صوت صراخ الناس لا يفارق اسمعتنا. 
ثم بعد ذلك مررنا بغابة كبيرة جداً. وبعدها وصلنا الى اسطنبول حيث نحن فيها الآن
عندما وصلنا إلى اسطنبول كانت المعيشة أصعب مما تخيلنا.
مما سبب لجوء الكثير من الاطفال للعمل لمساعدة الأهل.
كنت واحد منهم لمدة سنتين الى جاءت تسجي وقدمت لي منحة دراسية في مدرسة المناهل وكانت هذه بداية الرحلة الدراسية. 
تعرفت عليها عن طريق زيارة الجمعية لنا في المنزل حيث قدموا لنا معونة غذائية ثم سألوني فيما إذا كنت ادرس وكانت اجابتي لا ثم احتضنوني وحققوا حلمي بعد ان تحطم بدأت منذ أن تركت الدراسة في الصف الثاني.
شاركت في رسم هذه اللوحة وكانت مشاعر مفرحة جداً ومشوقة ومفيدة لأنها كانت تحوي على المعاني العميقة التي تؤثر في النفس وتشعل مشاعر العطف وأهم شعور هو الاحساس بالأخيرين. 
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page8_b;
