import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./024.png";
import Page24_b from "./Page24_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page24() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
不求事事如意，
<br />只求增加信心和勇氣。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Bù qiú shì shì rúyì，
<br />zhǐ qiú zēngjiā xìnxīn hé yǒngqì.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
لا تطلب أن يكون كل شيء كما تريده فقط تحلَّ بالشجاعة والقوة واستمرَّ وثابر.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Her şeyin istediğin gibi olmasını bekleme，sadece kendine güven ve cesaretini artır.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/ehzHA4gpYSc"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/oWipN0_Fkig"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/NNdGznAEWAA"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
وما نيلُ المطالبِ بالتمني  ..  ولــــــكنْ تُؤخذ الدنيا غلابا
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
المعنى الكلي: يجسّد البيت فكرة مهمة مفادها أن النجاح والطموحات تتطلب العمل والمثابرة، فلا يمكن للفرد أن يتوقّع تحقيق أهدافه من خلال الأحلام فقط، بل يجب عليه أن يسعى ويكافح للحصول على ما يريد.
عبرة: تشدّد العبارة على أهمية الاجتهاد والجد في سبيل تحقيق الطموحات، وأن السعي والمثابرة هما الطريق الحقيقي للنجاح، مما يحث الناس على التحلي بالعزيمة وعدم الاكتفاء بالتمني دون العمل.
</p>
 <h5 className="turkish-text mt-4">
Dilemekle hedeflere ulaşılmaz, Dünya mücadele edilerek ele geçirilir
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
所求無法一廂情願，但求努力無愧於心
(阿拉伯諺語)
</h5>
<p className="pinyin-text">
Suǒ qiú wúfǎ yīxiāngqíngyuàn dàn qiú nǔlì wúkuì yú xīn
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page24_b />
</section>
);
}
export default Page24;



