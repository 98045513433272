import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./03.png";
import Page3_b from "./Page3_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page3() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
用心看、用心聽、用心做，
<br />終身都受用。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Yòngxīn kàn，yòngxīn tīng，yòngxīn zuò，
<br />zhōngshēn dōu shòuyong.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
إن كانت تصــــرفاتك نابعة من قلبك تستفد من الذي تعلمته مدى الحياة.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Kalbinle bak， kalbinle dinle， kalbinle yap ömür boyu faydasını görürsün.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/2duBPlHw9nc"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/FXUF8Ne4XUE"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/8JrC3nlag6M"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
“اسْتَفْتِ قَلْبَكَ وَإِنْ أَفْتَاكَ النَّاسُ“  _ حديث شريف“
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
جاء أحد الصحابة واسمه وابصة بن معبد إلى النبي صلى الله عليه وسلم، فقال له النبي صلى الله عليه وسلم: “جئتَ تسأل عن البر والإثم؟“ فقال: نعم، فأرشده النبي صلى الله عليه وسلم إلى أن ينظر إلى قلبه ليعرف ما البر والإثم، فالبر ما اطمأنت إليه النفس، واطمأن إليه القلب، والإثم ما اشتبه عليه أمره، وتردد في الصدر؛ فإن لم يطمئن إليه القلب ولم ينشرح إليه الصدر فعليه أن يدعه، وإن قال له الناس: إن هذا الأمر جائز.
 ومعنى البر: حسن الخلق، وهي كلمة تدل على كثرة الخير من صلة وصدقة وحسن صحبة وعشرة.
</p>
 <h5 className="turkish-text mt-4">
“İnsanlar ne öğüt verirlerse versinler yalnızca kalbini dinle.“ _ Hadis-i Şerif
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
即使人們給你命令，你也要先捫心自問。
（聖訓）
</h5>
<p className="pinyin-text">
Jíshǐ rénmen gěi nǐ mìnglìng, nǐ yě yào xiān ménxīnzìwèn
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page3_b />
</section>
);
}
export default Page3;



