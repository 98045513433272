import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table } from "react-bootstrap";
import 'aos/dist/aos.css';
import AOS from 'aos';
import styled from 'styled-components';

// Import the JSON data
import arabicData from '../../Assets/ data/arabic_data.json';
import chineseData from '../../Assets/ data/chinese_data.json';
import turkishData from '../../Assets/ data/turkish_data.json';

// Custom styled components
const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: nowrap; /* Ensures buttons stay next to each other without wrapping */

  button {
    position: relative;
    padding: 8px 20px;
    background: linear-gradient(90deg, #395125, #585e6b);  
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    min-width: 150px; /* Ensures buttons have a minimum width */
  }

  button:hover {
    background: linear-gradient(90deg, #585e6b, #395125);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  .icon-1, .icon-2, .icon-3 {
    position: absolute;
    top: 0;
    right: 15px;
    width: 20px;
    transform-origin: 0 0;
    transform: rotate(10deg);
    transition: all 0.5s ease-in-out;
    filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.3));
  }

  button:hover .icon-1, button:hover .icon-2, button:hover .icon-3 {
    transform: rotate(15deg);
  }

  @media (max-width: 576px) {
    flex-wrap: wrap; /* Allows buttons to wrap on smaller screens */
    button {
      min-width: 100px; /* Reduces button size for mobile screens */
      padding: 6px 10px;
      font-size: 14px;
    }
  }
`;

// Styled table component
const StyledTable = styled(Table)`
  border: 2px solid #395125;  
  border-radius: 15px; 
  background-color: #f9f9f9;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);  
  width: 100%; /* Ensures table takes up full width */
  max-width: 100%; /* No more than full width */
  overflow-x: auto; /* Ensures horizontal scrolling for small screens */

  thead {
    background-color: #395125;  
    color: white;
    border-radius: 10px 10px 0 0;  
  }

  th, td {
    padding: 10px; /* Reduced padding for smaller screens */
    text-align: center;
    font-size: 14px; /* Reduced font size for better readability on smaller screens */
    border-bottom: 1px solid #ddd;  
  }

  tbody tr {
    transition: background-color 0.3s ease, transform 0.2s ease;
  }

  tbody tr:hover {
    background-color: #e6f3e6;  
    transform: scale(1.02);
  }

  @media (max-width: 576px) {
    th, td {
      font-size: 12px; /* Further reduce font size on smaller screens */
      padding: 8px;
    }

    table {
      display: block; /* Makes the table scrollable on small screens */
      overflow-x: auto;
    }
  }
`;

function Home2() {
  const [language, setLanguage] = useState('zh');

   useEffect(() => {
    let animationEffect = 'fade-up';

    switch (language) {
      case 'ar':
        animationEffect = 'fade-right';
        break;
      case 'zh':
        animationEffect = 'fade-left';
        break;
      case 'tr':
        animationEffect = 'fade-down';
        break;
      default:
        animationEffect = 'fade-up';
    }

    AOS.init({ duration: 1200, once: false });
    AOS.refresh();
  }, [language]);

  const renderTableContent = () => {
    let data = [];
    switch (language) {
      case 'ar':
        data = arabicData;
        break;
      case 'zh':
        data = chineseData;
        break;
      case 'tr':
        data = turkishData;
        break;
      default:
        return null;
    }

    return data.map((item, index) => (
      <tr key={index}>
        <td>{item.name}</td>
        <td>{item.current_job}</td>
        <td>{item.education}</td>
      </tr>
    ));
  };

  const renderHeaderText = () => {
    switch (language) {
      case 'ar':
        return "المشرفين";
      case 'zh':
        return "监督";
      case 'tr':
        return "Yöneticiler";
      default:
        return "المشرفين";
    }
  };

  const renderColumnHeaders = () => {
    switch (language) {
      case 'ar':
        return (
          <tr>
            <th>الاسم</th>
            <th>العمل الحالي</th>
            <th>المؤهلات العلمية</th>
          </tr>
        );
      case 'zh':
        return (
          <tr>
            <th>姓名</th>
            <th>当前工作</th>
            <th>学历</th>
          </tr>
        );
      case 'tr':
        return (
          <tr>
            <th>Adı Soyadı</th>
            <th>Şu Anki Meslek</th>
            <th>Nitelikler</th>
          </tr>
        );
      default:
        return (
          <tr>
            <th>الاسم</th>
            <th>العمل الحالي</th>
            <th>المؤهلات العلمية</th>
          </tr>
        );
    }
  };

  return (
    <section
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Container fluid className="home-about-section" id="about">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col xs={12} md={8} lg={7} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
              <p id="home-section" className="home-about-body1 text-center" style={{ fontSize: "24px", fontWeight: "bold" }}>
                {renderHeaderText()}
              </p>
              <div className="language-buttons mt-3">
                <StyledWrapper>
                  <button onClick={() => setLanguage('tr')}>
                    Türkçe
                    <div className="icon-3"></div>
                  </button>
                  <button onClick={() => setLanguage('zh')}>
                    中文
                    <div className="icon-2"></div>
                  </button>
                  <button onClick={() => setLanguage('ar')}>
                    العربية
                    <div className="icon-1"></div>
                  </button>
                </StyledWrapper>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col xs={12} data-aos="fade-up">
              <StyledTable striped bordered hover responsive>
                <thead>
                  {renderColumnHeaders()}
                </thead>
                <tbody>
                  {renderTableContent()}
                </tbody>
              </StyledTable>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Home2;
