import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./019.png";
import Page19_b from "./Page19_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page19() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
人生最大的成就，
<br />是從失敗中站起來。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Rénshēng zuìdà de chéngjiù，
<br />shì cóng shībài zhōng zhàn qǐlái.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
أعظم إنجاز في الحياة هو النهوض مرة أخرى بعد الفشل.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Hayattaki en büyük başarı，başarısızlıktan sonra yeniden ayağa kalkmaktır.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/esPp2x_lRJE"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/MnWKlHzmsUI"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/DcdNEGWS2vg"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
واعلَمْ أنَّ النَّصرَ في الصَّبرِ ، وأنَّ الفرَجَ مع الكرْبِ ، وأنَّ مع العُسرِ يُسرًا.“ _ حديث شريف"
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
هذا جزء من حديث يربط العبد بربه بدون واسطة.
فالصبر على الشدائد التي يكرهها الإنسان فيه خير كثير للعبد، وهو أفضل له من الجزع، والصبر مفتاح كل خير، ولا بد أن يكلل الصبر بالنصر.
وأن الفرج مع الكرب، الكرب شدة الضيق في حياة الإنسان، فكلما اشتد الكرب كلما قرب الفرج.
فرحمة الله بعباده قريبة فيجعل مع الضيق والشدة تفريجاً، فلا ييأس العبد مهما أصابه.
</p>
 <h5 className="turkish-text mt-4">
“Bil ki zafer sabırla, ferahlık sıkıntıyla ve kolaylık zorlukla birliktedir.“ _ Hadis-i Şerif
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
必須要知道，勝利伴隨著耐心
解脫伴隨著痛苦，容易伴隨著困難。(聖訓)
</h5>
<p className="pinyin-text">
Bìxū yào zhīdào, shènglì bànsuízhe nàixīn 
jiětuō bànsuízhe tòngkǔ, róngyì bànsuízhe kùnnán
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page19_b />
</section>
);
}
export default Page19;



