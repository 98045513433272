import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileRAGADTUTANJI from './RAGAD TUTANJI.png';
function Page40_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
RAGADTUTANJI:2009,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">RAGAD TUTANJI </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.RAGADTUTANJI} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileRAGADTUTANJI}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
在經過多次的偷渡嘗試後，我們終於來到了土耳其。這段旅程充滿了苦難與勞累。
我們來到了伊斯坦堡，在那裡經歷了許多困難，但幸運的是，有慈濟在我們的避難過程中給予了支持。我終於可以在滿納海學校登記入學，這對我來說是一個巨大的喜悅，特別是在多年中斷學業後，我幾乎放棄了繼續學習的希望。慈濟也提供了“BIM卡”，在生活困難的時候給予了我們莫大的幫助，至今仍在繼續支持我們。
我畫了一幅以證嚴法師的話為主題的畫作，內容傳達了作為一名好兒子或好女兒，不僅僅是聽從父母的話，更要用心去關愛和取悅他們，讓他們感到幸福。
這幅畫非常美麗，讓我對生活充滿感激之情。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
بعد محاولات كثيرة عن طريق التهريب دخلنا إلى تركيا، رحلة فيها الكثير من العذاب والتعب.
أتينا إلى إسطنبول عانينا فيها الكثير، لكن كان (لمؤسسة تسجي) الفضل في الوقوف معنا في لجوئنا، سجلت في (مدرسة المناهل) كانت فرحة كبيرة وخاصة بعد انقطاع لسنوات طويلة حتى يئست من استكمال الدراسة ، وقدمت لنا المؤسسة ومازالت كروت بيم التي تخفف عنا صعوبة الحياة هنا.
رسمت لوحة من كلمات (الماستر) التي تتضمن أن يكون الإنسان ابنآ صالحآ أو ابنة صالحة لا يعني فقط الاستماع إلى والديه، ولكن آيضاً أن يكون لطيفآ معهم لإسعادهم.
كانت لوحة جميلة جداً.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page40_b;
