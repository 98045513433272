import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileNOURALMARI from './NUR MARI.png';
function Page47_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
NOURALMARI:2005,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">NOUR AL MARI </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.NOURALMARI} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileNOURALMARI}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
2014年，我們透過走私來到土耳其，經歷了一段艱難而艱苦的旅程，我們進入了伊斯坦布爾，並聽說了慈濟基金會，它幫助人們。
我從二年級一直就讀滿納海學校直到獲得學士學位，我生活在一個愛我並為我提供心理、社會和教育支持的大家庭中。
我很喜歡參與畫這幅畫，它表達了良好的道德和對他人的寬容。
我謝謝你證嚴法師為了這種關懷，您為我們提供了這種智慧。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
تينا إلى تركيا عام 2014 عن طريق التهريب في رحلة صعبة وقاسية، دخلنا إلى إسطنبول وسمعنا عن (مؤسسة تسجي) التي تساعد الناس، 
سجلت في (مدرسة المناهل) من الصف الثاني حتى بكالوريا وعشت في عائلة كبيرة تحبني وتقدم لي الدعم والنفسي والاجتماعي والتربوي.
أحببت أن أشارك في رسم هذه اللوحة التي تعبر عن الأخلاق الحميدة والتسامح مع الآخرين.
أنني أشكر
( الماستر) 
على هذه الرعاية التي تقدمها لنا وعلى هذه الحكمة.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page47_b;
