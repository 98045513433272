import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileCEDRASALAME from './CEDRA SALAME .png';
import profileSENAHABOUSH from './SENA HABUS.png';
function Page32_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
CEDRASALAME:2006,
SENAHABOUSH:2010,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">CEDRA SALAME </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.CEDRASALAME} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileCEDRASALAME}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
莎德拉. 沙萊即
我和我的兄弟們從敘利亞來到土耳其，通過巴布薩拉瑪關口，那時我只有八歲。 
我在工廠工作了一整年，我的父母留在了敘利亞。當我的母親來到土耳其時，她幫我在滿納海學校註冊。
當我得知自己將上學而不是工作時，我感到非常高興。
滿納海學校給了我家庭補助，讓我不用到工廠工作
我想回報這份恩情，並在我的畫作中表達我對證嚴法師為成千上萬的難民所做的一切的感激之情，這雖然只是一件小事，但它的意義非常大。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
قدمت مع أخوتي من سورية إلى تركيا عبر معبر باب السلامة، كنت عمري حينها لم يتجاوز الثماني سنوات. 
عملت في الورشة لمدة سنة كاملة، بقي والداي في سوريا، لما أتت أمي إلى تركيا سجلتني في (مدرسة المناهل) وكانت فرحتي كبيرة عندما علمت أنني سأدرس وأترك العمل.
قدمت لي (مدرسة المناهل) تعويضاً عن راتبي الذي كنت أتقاضاه في العمل.
أردت أن أرد الجميل وأعبر في رسمتي عن امتناني لما فعلت (الماستر) من أجل آلاف اللاجئين، وهو يعتبر شيئاً بسيطاً، لكن دوره المعنوي كان كبيراً..
</p>
</Col>
</Row>
 </Container>
 </Container>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">SENA HABOUSH</strong>
</h1>
<h1 className="heading-name">
My age now: {ages.SENAHABOUSH} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileSENAHABOUSH}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
在敘利亞家園遭到轟炸後，我與家人搬到了土耳其。
我在戰爭開始就開始了從敘利亞到土耳其的旅程，我們開始了兩年沒有受過教育的新生活，日子充滿了挑戰。 最後，我到達了伊斯坦堡的滿納海學校。 
這是一個提供安全的地方。 
感謝  慈濟基金會和 滿納海學校的幫助，我得以繼續學業並得到照顧。
我的畫作反映了靈活性的價值觀，體現了我學到的智慧，他關於寬容和支持他人的重要價值觀
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
بعد فقدان منزلي في سوريا وتعرضه للقصف، انتقلت مع عائلتي إلى تركيا.
بدأت رحلتي من سوريا إلى تركيا خلال الحرب، بدأنا حياة جديدة بدون تعليم لمدة سنتين، وكانت الأيام مليئة بالتحديات. وفي النهاية، وصلت إلى مدرسة المناهل في إسطنبول. فكانت مكانًا يوفر الأمان والدعم. بفضل مساعدةجمعية تسجي ومدرسة المناهل، تمكنت من مواصلة تعليمي والحصول على الاهتمام والرعاية. 
لوحتي تعكس قيم التقبل والمرونة، مجسدة حكمة تعلمتها عن قيمة التسامح وأهمية مساندة الآخرين.
أشعر بامتنان عميق للزعيمة التي ألهمتني، ولأني أحب الرسم كثيرًا، قررت أن أعبر عن كلماتها عبر الفن، ليس فقط لإظهار امتناني، ولكن لكي تبقى هذه العبارات محفورة في أذهان الجميع بطريقة جميلة ومؤثرة.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page32_b;
