import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./09.png";
import Page9_b from "./Page9_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page9() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
認真學習要用到:赤子的真誠、
<br />駱駝的耐力、獅子的勇猛。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Rènzhēn xuéxí yào yòng dào: Chìzǐ de zhēnchéng， 

<br />luòtuó de nàilì，shīzi de yǒngměng.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
عندما نملك براءة الأطفال وتحمّل الجِمال وشجاعة الأسود عندها نستطيع التعلم.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Çocukların masumiyeti，develerin dayanıklılığı ve aslanların cesaretiyle öğrenmeyi başarabiliriz.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/PvupEi9vKHs"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/4oMXzTnH5v8"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/loxrzZyAFz0"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
لا تحســـبنّ المجدَ تمراً أنت آكله  ..  لن تبلغ المجد حتى تلعق الصبرا
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
التميز والتألق حلم يداعب خيال كل إنسان، وطموح مشروع لكل مجتهد، والكل يسعى إليه لكنّ الواصلين قلّة، هم الذين آمنوا بقدراتهم واستثمروا طاقاتهم، وتحمّلوا المشاق، وحاربوا اليأس بالأمل، والكسل بالعمل، وأدركوا أنّ بلوغ الهدف يتطلب ثمنا باهظا من الجد والصبر والمثابرة.
</p>
 <h5 className="turkish-text mt-4">
Şanı, yiyebileceğin bir hurma sanma; sabrı tatmadan şana erişemezsin
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
不要把榮耀簡單視為你吃的棗子那麼容易
除非你忍耐，否則你不會獲得榮耀。
（阿拉伯諺語）
</h5>
<p className="pinyin-text">
Bùyào bǎ róngyào jiǎndān shì wéi nǐ chī de zǎozi nàme róngyì chúfēi nǐ rěnnài, fǒuzé nǐ bù huì huòdé róngyào
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page9_b />
</section>
);
}
export default Page9;



