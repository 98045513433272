import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./030.png";
import Page30_b from "./Page30_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page30() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
時時面帶笑容，要別人笑，自己要先笑。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Shí shí miàn dài xiàoróng，yào biérén xiào，zìjǐ yào xiān xiào.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
إذا أردت أن يبتسم لك الآخرون فعليك أن تبتسم لهم أولاً.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Her zaman mütebessim olun. Başkalarının size gülümsemesini istiyorsanız，önce siz gülümseyin.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/BOBPmb1yDBg"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/2QNvaEjkjbM"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/lKDLXzkM27A"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
البشاشة حَبالةُ المودة. _ مثل عربي
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
ليس اللسان وحده وسيلة للتخاطب بين البشر، بل تعابير الوجه ونظرات العيون تُعدّ من أبرز وسائل التعبير وأرقها. فكم من شخص أحببناه لابتسامته ورقة نظرته، وكم من آخر خشينا الاقتراب منه لعبوس وجهه وتقطّب حاجبيه وحدة النظر من عينيه، فابتسامتك في وجه أخيك رسالة معبرة تدل على تواضعك وطيبتك ولين جانبك، فاحرص عليها لأنها سبيل لطيف خفيف لنيل احترام وحب الآخرين.
</p>
 <h5 className="turkish-text mt-4">
Güler yüz dostluk bağlarını güçlendirir. _ Arap atasözü
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
笑容是一條感情的繩索。
(阿拉伯諺語)
</h5>
<p className="pinyin-text">
Xiàoróng shì yītiáo gǎnqíng de shéngsuǒ
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page30_b />
</section>
);
}
export default Page30;



