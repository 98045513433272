import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileMARAMBADET from './MARAM BAKHET.png';
function Page33_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
MARAMBADET:2005,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">MARAM BADET </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.MARAMBADET} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileMARAMBADET}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
2011年，我們經過一段艱難而充滿挑戰的旅程來到了土耳其。 
滿納海學校就像我們的第二個家，它在經濟和精神上支持我們，並幫助我們這些孩子接受教育。 
我透過鄰居知道這所學校並開始在那裡學習，在那裡我找到了類似於伊斯蘭教原則的家人和支持。 
學校教會了我謙遜和與他人分享的價值。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
في 2011، وصلنا إلى تركيا بعد رحلة قاسية ومليئة بالتحديات. (((مدرسة المناهل))) كانت لنا بمثابة بيت ثاني، فقد دعمتنا ماديًا ومعنويًا وساعدت في تعليم أطفالنا. تعرفت على المدرسة من خلال الجيران وبدأت الدراسة هناك، وجدت فيها عائلتي ودعمًا يشبه مبادئ الإسلام. لقد علمتني المدرسة قيمة التواضع والمشاركة مع الآخرين.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page33_b;
