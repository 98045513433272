import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/home.png"; // نفس الصورة المستخدمة في الصفحة السابقة
import Home2 from "./Home2"; // استبدل Page1_b بـ Home2 
import Tilt from "react-parallax-tilt";
import AOS from "aos";
import 'aos/dist/aos.css'; // الاحتفاظ بـ AOS

function Home() {
  useEffect(() => {
    AOS.init({ duration: 1200, once: false });
  }, []);

  return (
    <section>
      <Container fluid className="home-about-section" id="about">
        <Container>
          <Row className="justify-content-center">
            <Col
              md={6}
              className="home-about-description d-flex flex-column align-items-center"
              data-aos="fade-right"
            >
              <p
                id="home-section"
                className="home-about-body text-center chinese-text"
              >
                   靜思語
                   <br />
                   一束智慧之花-
                   <br />
                   通往文學、倫理和人類幸福的途徑
                <div
                  className="arabic-text"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                باقة من زهور  منهج في الأدب والأخلاق والسعادة الإنسانية

                </div>
              </p>
            </Col>
            <Col md={6} className="myAvtar" data-aos="fade-left">
              <Tilt>
                <img src={myImg} className="img-fluid" alt="avatar" />
              </Tilt>
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 /> 
    </section>
  );
}

export default Home;
