import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileSIDRASALAMEH from './SIDRA SALAMEH.png';
import profileALAAFALAHA from './alaa falah.png';
function Page16_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
SIDRASALAMEH:2006,
ALAAFALAHA:2008,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">SIDRA SALAMEH</strong>
</h1>
<h1 className="heading-name">
My age now: {ages.SIDRASALAMEH} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileSIDRASALAMEH}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
莎德拉. 沙萊即
我和我的兄弟們從敘利亞來到土耳其，通過巴布薩拉瑪關口，那時我只有八歲。 
我在工廠工作了一整年，我的父母留在了敘利亞。當我的母親來到土耳其時，她幫我在滿納海學校註冊。
當我得知自己將上學而不是工作時，我感到非常高興。
滿納海學校給了我家庭補助，讓我不用到工廠工作
我想回報這份恩情，並在我的畫作中表達我對證嚴法師為成千上萬的難民所做的一切的感激之情，這雖然只是一件小事，但它的意義非常大。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
قدمت مع أخوتي من سورية إلى تركيا عبر معبر باب السلامة، كنت عمري حينها لم يتجاوز الثماني سنوات. 
عملت في الورشة لمدة سنة كاملة، بقي والداي في سوريا، لما أتت أمي إلى تركيا سجلتني في (مدرسة المناهل) وكانت فرحتي كبيرة عندما علمت أنني سأدرس وأترك العمل.
قدمت لي (مدرسة المناهل) تعويضاً عن راتبي الذي كنت أتقاضاه في العمل.
أردت أن أرد الجميل وأعبر في رسمتي عن امتناني لما فعلت (الماستر) من أجل آلاف اللاجئين، وهو يعتبر شيئاً بسيطاً، لكن دوره المعنوي كان كبيراً..
</p>
</Col>
</Row>
 </Container>
 </Container>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">ALAA FALAHA </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.ALAAFALAHA} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileALAAFALAHA}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我們於2013年抵達土耳其，逃避了家鄉戰火的肆虐。在這片異鄉，我們面臨著重重挑戰，包括語言不通和經濟困難。為了幫助家庭渡過難關，我的三個兄弟姐妹不得不放棄學業，投身工作。
證嚴法師的話語深深打動了我的心靈，讓我無比感動。我決定將這些話化為一幅簡單而富有表達力的畫作，藉此傳達我在閱讀這些話語時所感受到的仰慕與愛意。希望這幅畫能夠吸引觀者的目光，讓他們追問畫作背後的故事以及靈感的來源，進而被這些話語打動，並受到啟發而付諸行動。
在那段艱難的時光裡，我們全家經歷了許多無法想像的困境。每一天的生活都像是一場考驗，語言的障礙讓我們倍感孤獨，經濟的壓力也讓我們寸步難行。看著兄弟姐妹們為了生計不得不放棄學業，我的心中充滿了酸楚與不捨。然而，證嚴法師的教誨如同一縷光，照亮了我們前行的道路。她的話語帶給我無限的力量與勇氣，讓我明白在最黑暗的時刻，也能夠找到光明與希望。
這幅畫不僅是我的情感寫照，更是我對證嚴法師深深敬仰與感激之情的表達。我希望透過這幅畫，能將那份來自心底的愛與力量傳遞給每一位觀者。希望他們也能被這些話語感動，從中找到屬於自己的力量，去面對生活中的挑戰與困難。這是我心底最深的期盼，也是我創作這幅畫的初衷。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
وصلنا إلى تركيا عام 2013 فارين من الحرب الدائرة في بلادنا، واجهنا في بلاد اللجوء تحديات عديدة، بما في ذلك صعوبة اللغة والأوضاع المالية الصعبة حتى أن اضطروا أخوتي الثلاثة أن يعملوا ويتخلوا عن إكمال دراستهم.
لامس كلام (الماستر) قلبي وأثر بي فأردت أن أخلد كلامها بلوحة بسيطة معبرة أفرغ بها مشاعر الإعجاب والحب التي راودتني أثناء قراءتي لكلامها ولتبهر الناظر ويسأل عن سبب رسمها، وكيف أستوحيتها، ولعله يتأثر بكلامها ويعمل به.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page16_b;
