import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./042.png";
import Page42_b from "./Page42_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page42() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
懂得照顧好自己，就能使父母寬懷。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Dǒngdé zhàogù hǎo zìjǐ，jiù néng shǐ fùmǔ kuān huái.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
إذا كنتَ لا تريد أن يقلق عليك والداك فعليك أن تعتني بنفسك جيداً.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Kendine iyi bakmayı bilirsen，ebeveynlerinin içini ferahlatırsın.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/Z-k6beczQL4"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/4NFj72_Usl4"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/cEv_DiFDcdY"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
“مَنْ قَرَأَ الْقُرْآنَ وَعَمِلَ بِمَا فِيهِ أُلْبِسَ وَالِدَاهُ تَاجاً يَوْمَ الْقِيَامَةِ.“ _ حديث شريف
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
كر النبي صلى الله عليه وسلم مما ينتفع الوالدان بفعل ولديهما، هو قراءته للقرآن وتعلمه والعمل به بما فيه أي من الأخلاق والآداب والأحكام؛ فأتمر بأوامره، واجتنب نواهيه، واتعظ بمواعظه.
فإنه سيكسى والداه تاجاً يوم القيامة ضوؤه أحسن من ضوء الشمس.
</p>
 <h5 className="turkish-text mt-4">
“Kim Kur'an'ı okur ve içindekilerle amel ederse, kıyamet günü anne ve babası taç giyer.“ _ Hadis-i Şerif
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
誰閱讀《古蘭經》並按照其中的內容行事
他的父母都會在復活日戴上王冠 
(聖訓)
</h5>
<p className="pinyin-text">
Shuí yuèdú “gǔlánjīng” bìng ànzhào qízhōng de nèiróng xíngshì tā de fùmǔ dūhuì zài fùhuó rì dài shàng wángguàn
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page42_b />
</section>
);
}
export default Page42;



