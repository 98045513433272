import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./020.png";
import Page20_b from "./Page20_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page20() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
信心、毅力、勇氣三者具備，
<br /> 則天下沒有做不成的事。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Xìnxīn， yìlì，yǒngqì sān zhě jùbèi，
<br />zé tiānxià méiyǒu zuò bùchéng de shì.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
مع الثقة والمثابرة والشجاعة، لا يوجد شيء صعب في العالم.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
İnanç，azim ve cesaret varsa，dünyada hiçbir şey zor değildir.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/4pYtU50njJU"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/ozXOawHhziM"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/zW5MZq475hs"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
إذا الشعب يوماً أراد الحياة  ..  فلا بد أن يســـــــتجيبَ القدر
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
عندما تسعى أمة أو فرد لغاية نبيلة عظيمة فإن ذلك لا يتحقق إلا بالعزيز من التضحيات، تضحيات بالوقت والجهد والمال والنفس، فمن يبذل ذلك تنقاد له أمنياته، وتتحقق أهدافه وغاياته، فالقدر لا يحابي أحدا، ولا يبتسم ولا يكافئ إلا المجدين الصابرين، ولا عزاء للكسالى الخاملين.
</p>
 <h5 className="turkish-text mt-4">
Eğer bir gün halk yaşamayı dilerse, kader mutlaka yanıt verir.
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
人們要活下去，就必須面對命運。
 (阿拉伯諺語)
</h5>
<p className="pinyin-text">
Rénmen yào huó xiàqù, jiù bìxū miàn duì mìngyùn
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page20_b />
</section>
);
}
export default Page20;



