import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileAYSHAALMUSTAFA from './AYSE ALMUSTAFA.png';
function Page49_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
AYSHAALMUSTAFA:2007,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">AYSHA  AL MUSTAFA </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.AYSHAALMUSTAFA} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileAYSHAALMUSTAFA}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
「2014年，我們因躲避敘利亞的戰火，被迫離開家園，來到土耳其。在伊斯坦堡生活了一年後，透過朋友的介紹，我們認識了慈濟基金會。當時，我們的生活十分艱困，非常感謝慈濟提供的各種援助，減輕了我們的負擔。

從慈濟的志工身上，我學到了笑容的重要性。他們總是帶著溫暖的笑容，這讓我覺得非常溫暖。人們需要這樣的笑容，就像慈濟給予我們的那樣，能撫慰人心。因此，我選擇了與笑容相關的話語，因為它對我的人生影響深遠。我真希望全世界都能充滿笑容。」
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
في 2014 خرجنا من سوريا إلى تركيا بسبب الحرب في سوريا خوفاً من أن يصيبنا الأذى 
وبعد وصلنا إلى إسطنبول بسنة تعرفنا عن طريق الأصدقاء على مؤسسة تسجي، فنحن 
نواجه صعوبة في الوضع المعيشي، ونشكر المؤسسة على ما تقدمه من مساعدات للتخفيف عنا.
تعلمت من متطوعي (مؤسسة تسجي) أهمية الابتسامة، فدائماً أراهم مبتسمين، فالناس بحاجة إلى وجوه مبتسمة تبتسم لهم مدى الحياة مثل ما ابتسمت لنا (مؤسسة تسجي)، لذلك اخترت العبارة التي تتكلم عن الابتسامة لأنها أثرت أثراً كبيراً في حياتي، ليت العالم بأكمله دائماً مبتسماً. . .

</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page49_b;
