import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileBAYANALSHEFFONY from './BAYAN ALSHAFFOUNY.png';
import profileALAAALDABAK from './Alaa aldabak.png';
function Page4_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
BAYANALSHEFFONY:2005,
ALAAALDABAK:1983,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">BAYAN ALSHEFFONY </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.BAYANALSHEFFONY} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileBAYANALSHEFFONY}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我是學生貝央，我開始在滿納海學校學習，透過我的朋友的幫助下認識了慈濟
去滿納海學校是我生命中的一件大事。
我的生活變得有意義了。
 感謝我的老師和同學，使我有動力取得成功和超越自我
歲月流逝，直到我進入12年級
總之，像任何一年一樣，我們有各種科目的專案。包括繪畫材料，但這次不同，它非常漂亮，因為它要求我們將證嚴法師的話語體現在富有表現力的圖畫中。
起初，我開始選擇這句話，然後在與我一起的老師的説明下，我開始一步一步地畫畫。
我對這幅畫的每個部分的感受都不同，起初非常困難。
但是當我理解了這句話的意思時，我覺得我好像在畫中聽到這句話，這句話是關於時間及其價值的，當我們浪費它時，它就會像沙子一樣丟失。
對我來說，因為今年是我在學校的最後一年，我需要理解時間價值的意義，因為它對我來說非常重要，我必須管理好它才能取得好成績。
最後，我要感謝慈濟人給我一個機會，讓我更接近證嚴法師並更好地理解她，我感謝證嚴法師為我們提供的所有物質和精神支援。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
انا الطالبة بيان الشفوني بدأت الدراسة في مدرسة المناهل وتعرفت عليها بفضل زملائي.
كان ذهابي إلى المناهل حدث كبير في حياتي.
أصبحت لحياتي معنى.
 وبفضل أساتذتي وزملائي أصبح لدي دافع لكي أنجح وأتفوق.
وبدأت السنين تمضي إلى أن أصبحت في سنتي الأخيرة.
وفي الختام ومثل أي سنة دراسية كان لدينا مشاريع لمختلف المواد. ومنها مادة الرسم ولكن هذه المرة كان مختلف واختلافه جميل جدا لأنه طُلب منا أن نجسد كلمات وحكم الماستر إلى رسومات معبرة.
بداية الأمر بدأتُ في اختيار العبارة وبعدها بدأت بالرسم بمساعدة المشرفات كانوا معي خطوة بخطوة.
كانت مشاعري مختلفة في كل قسم من اللوحة في بداية الأمر كان الأمر صعباً جداً.
ولكن عندما فهمت معنى العبارة أحسست وكأنني أرسم وأسمع العبارة من الماستر شخصيا، كانت العبارة عن الوقت وعن قيمته وعندما نضيعه يضيع مثل الرمال.
بالنسبة لي ولأنني في سنتي الدراسية الأخيرة كنت بحاجة لفهم معنى قيمة الوقت لأنه مهم جداً لي ويجب على إدارته جيداً لأحصل على نتيجة جيدة.
وأخيرا أريد شكر الأستاذ الفاضل فيصل وآنساتي لإعطائي فرصة للتقرب من الماستر وفهمها بشكل أفضل وأشكر الماستر على كل ما قدمته لنا من دعم مادي ومعنوي.
</p>
</Col>
</Row>
 </Container>
 </Container>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name"> ALAA AL DABAK </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.ALAAALDABAK} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileALAAALDABAK}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我來到土耳其是因為2016年的戰爭，我和我的父母來到這。
在到達土耳其三個月之後，我來到伊斯坦堡，到我哥哥家，他為我找到一份合適的工作。
我開始尋找一份適合我的工作，並在 Facebook 上找到一則關於蘇丹加濟地區 滿納海學校電腦教師職業的廣告。
當時，我對學校、基金會甚至這個地區一無所知。
我來到學校，向行政部門提交了我的檔案，經過考試和面試，我被學校錄取了。
當時我非常高興，這是我在滿納海學校和慈濟基金會開始志工服務的地方，我從上班的第一天起就開始做志工，為前來基金會尋求幫助的人提供協助當有被幫助的人說我很好運和幸福時，我感謝真主因為我真的感到幸福。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
أتيت إلى تركيا بسبب الحرب عام ٢٠١٦ مع امي وابي.
بعد معاناة محاولة الوصول إلى تركيا لمدة ثلاث أشهر.
أتيت إلى اسطنبول إلى بيت اخي لغاية إيجاد عمل مناسب لي.
وبدأت بالبحث عن عمل يناسبني وقرأت اعلان في الفيسبوك عن مهنة معلم حاسوب في مدرسة المناهل بمنطقة السلطات غازي. حينها لم أكن أعرف اي شيء عن المدرسة ولا الجمعية ولا حتى المنطقة. 
أتيت إلى العنوان وقدمت ملفي للإدارة وبعد فحص ومقابلات تم قبولي في المدرسة. 
وكانت فرحتي عارمة حين ذلك. 
وهنا بدأت رحلتي التطوعية في مدرسة المناهل ومع مؤسسة تسجي الخيرية، وبدأت التطوع منذ الأيام الأولى لي في المدرسة في تقديم المساعدة للآخرين الذين يأتون إلى الجمعية طلبا للمساعدة.
 شعور السعادة والفرح يغمرني عندما يدعو لي أحد المحتاجين بالتوفيق والسعادة.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page4_b;
