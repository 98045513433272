import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Biography_b() {
  return (
    <section>
      {/* Section for biography introduction */}
      <Container fluid className="home-section">
        <h1 id="#my-life-section" className="project-heading" data-aos="flip-down">
        introduce about <strong className="purple">   master Cheng Yen</strong>
        </h1>
      </Container>

      {/* Story Section */}
      <Container fluid className="Story-section">
        <Container>
          <Row className="justify-content-center">
            {/* First Chinese and Arabic block */}
            <Col md={6} xs={12} className="text-left" data-aos="fade-right">
              <p className="chinese-text">
                <span style={{ color: '#585e6b' }}>1966</span>年，5月，在普明寺正式成立「人日存下五毛買菜錢，每月集資一千多元，開始從事濟貧救苦的工作。
              </p>
            </Col>

            <Col md={6} xs={12} className="text-right" data-aos="fade-left">
              <p className="arabic-text">
                <span style={{ color: '#585e6b' }}>1966</span> تم تأسيس “جمعية تسجي” رسميًا، حيث قام ستة من زملاء الماستر بصنع زوج إضافي من أحذية الأطفال يوميًا، بينما قامت ثلاثون امرأة بتوفير خمسين سنتًا من مصروفات شراء الطعام يوميًا، مما جمع أكثر من ألف يوان شهريًا. بدأت الجمعية في الانخراط في أعمال تخفيف الفقر ومساعدة المحتاجين.

              </p>
            </Col>

            {/* Second Chinese and Arabic block */}
            <Col md={6} xs={12} className="text-left" data-aos="fade-right">
              <p className="chinese-text">
                <span style={{ color: '#585e6b' }}>1982</span>年，證嚴上人發起建院後，感於培養良好醫事人才的重要，而於 1982年開始，委託慧炬雜誌社代辦「慈濟醫學清寒獎助學金」，每名兩萬五千元。

              </p>
            </Col>

            <Col md={6} xs={12} className="text-right" data-aos="fade-left">
              <p className="arabic-text">
                <span style={{ color: '#585e6b' }}>1982</span>،, البدء في إنشاء المستشفى، والشعور بأهمية تنمية المواهب الطبية الجيدة.

              </p>
            </Col>

            {/* Third Chinese and Arabic block */}
            <Col md={6} xs={12} className="text-left" data-aos="fade-right">
              <p className="chinese-text">
                <span style={{ color: '#585e6b' }}>1986</span>，集合十方善心籌建的「慈濟綜合醫院」落成啟業。
                本著尊重生命的理念，除了病患住院免收保證金，貧困患者協助尋求社會援助之外，更不計成本致力提升醫療設備與技術，一改花東地區醫療荒漠的宿命。
              </p>
            </Col>

            <Col md={6} xs={12} className="text-right" data-aos="fade-left">
              <p className="arabic-text">
                <span style={{ color: '#585e6b' }}>1986</span>, تم الانتهاء من افتتاح “مستشفى تسجي العام” الذي تم بناؤه بجهود خيرية من جميع أنحاء العالم.

              </p>
            </Col>

            {/* Fourth Chinese and Arabic block */}
            <Col md={6} xs={12} className="text-left" data-aos="fade-right">
              <p className="chinese-text">
                <span style={{ color: '#585e6b' }}>1989</span>年，以培育慈懷柔腸、視病如親的白衣大士為目標的「慈濟護理專科學校」正式創校開學。這是全臺唯一由私人創辦，但提供公費就讀的護理專科學校，並於1996年獲教育部同意，單獨招收原住民免費生（完全免費就學，並提供生活費），使照顧原住民的願望終於落實。

              </p>
            </Col>

            <Col md={6} xs={12} className="text-right" data-aos="fade-left">
              <p className="arabic-text">
                <span style={{ color: '#585e6b' }}>1989</span>, تم افتتاح “كلية تسجي للتمريض “ رسميًا بهدف تنشئة أطباء متعاطفين يعالجون المرضى كما لو كانوا أفرادًا من العائلة. هذه هي كلية التمريض الوحيدة في تايوان التي أسسها شخص خاص ولكنها توفر دروسًا عامة. في عام 1996، تمت الموافقة عليها من قبل وزارة التعليم لتسجيل الطلاب من السكان الأصليين بشكل مستقل مجانًا.

              </p>
            </Col>

            {/* Fifth Chinese and Arabic block */}
            <Col md={6} xs={12} className="text-left" data-aos="fade-right">
              <p className="chinese-text">
                <span style={{ color: '#585e6b' }}>1991</span>年，募款援助波斯灣戰火孤兒、孟加拉風災水患災民，踏出慈濟國際賑災的腳印。證嚴上人獲頒麥格塞塞獎，並將獎金三萬美元一分為二，作為中國大陸賑災基金及菲國救災款項。

              </p>
            </Col>

            <Col md={6} xs={12} className="text-right" data-aos="fade-left">
              <p className="arabic-text">
                <span style={{ color: '#585e6b' }}>1991</span>,  , جمع الأموال لمساعدة أيتام الحرب في الخليج العربي وضحايا الأعاصير والفيضانات في بنجلاديش، سيراً على خطى جهود الإغاثة الدولية التي تبذلها تسجي  حصلت الماستر على جائزة ماجسايساي، وتم تقسيم الجائزة البالغة 30 ألف دولار أمريكي إلى جزأين لاستخدامها كأموال للإغاثة من الكوارث في الصين القارية والفلبين.
              </p>
            </Col>

            {/* Sixth Chinese and Arabic block */}
            <Col md={6} xs={12} className="text-left" data-aos="fade-right">
              <p className="chinese-text">
                <span style={{ color: '#585e6b' }}>1994</span> 年，「慈濟醫學院」創校開學。證嚴上人期許這些未來的大醫王「功能與良能平行，知識與智慧並重」。除了醫學專業課程之外，亦開設花道、茶道、書法、藝術欣賞等人文通識課程，以及鼓勵學生多參與社會服務。


              </p>
            </Col>

            <Col md={6} xs={12} className="text-right" data-aos="fade-left">
              <p className="arabic-text">
                <span style={{ color: '#585e6b' }}>1994</span>, تأسيس وافتتاح “كلية تسجي للطب “. وأمل الماستر أن يكون لدى هؤلاء الأطباء العظماء في المستقبل “وظائف متوازية وقدرات جيدة، وتركيز متساوٍ على المعرفة والحكمة”.  الإضافة إلى الدورات المهنية الطبية، تقدم الكلية أيضًا دورات في العلوم الإنسانية العامة مثل تنسيق الزهور، وحفلات الشاي، والخط، وتقدير الفن، وتشجع الطلاب على المشاركة في الخدمات الاجتماعية.
              </p>
            </Col>

            {/* Seventh Chinese and Arabic block */}
            <Col md={6} xs={12} className="text-left" data-aos="fade-right">
              <p className="chinese-text">
                <span style={{ color: '#585e6b' }}>1998</span>年，慈濟大愛電視臺開播。《經典》雜誌創刊。
              </p>
            </Col>

            <Col md={6} xs={12} className="text-right" data-aos="fade-left">
              <p className="arabic-text">
                <span style={{ color: '#585e6b' }}>1998</span>, تم إطلاق محطة Tzu Chi Great Love TV.
 
              </p>
            </Col>

            {/* Eighth Chinese and Arabic block */}
            <Col md={6} xs={12} className="text-left" data-aos="fade-right">
              <p className="chinese-text">
                <span style={{ color: '#585e6b' }}>1999</span>年，3月，玉里慈濟醫院啟業，降低南花蓮地區「南病北送」的求醫風險，並落實社區醫療照護，保障偏遠地區鄉親的生命與健康。


              </p>
            </Col>

            <Col md={6} xs={12} className="text-right" data-aos="fade-left">
              <p className="arabic-text">
                <span style={{ color: '#585e6b' }}>1999</span>, في مارس، تم افتتاح مستشفى “يولي تسجي“ للحد من مخاطر طلب العلاج الطبي في منطقة جنوب هوالين وتنفيذ الرعاية الطبية المجتمعية لحماية حياة وصحة الناس في المناطق النائية
              </p>
            </Col>

            {/* Ninth Chinese and Arabic block */}
            <Col md={6} xs={12} className="text-left" data-aos="fade-right">
              <p className="chinese-text">
                <span style={{ color: '#585e6b' }}>2000</span>年，慈濟大學附屬慈濟中學暨慈濟實驗小學創校招生，正式邁向自幼稚園到研究所一貫連續教育的「教育完全化」新里程。

              </p>
            </Col>

            <Col md={6} xs={12} className="text-right" data-aos="fade-left">
              <p className="arabic-text">
                <span style={{ color: '#585e6b' }}>2000</span>, افتتحت مدرسة تسجي المتوسطة ومدرسة تسجي الابتدائية التجريبية التابعة لجامعة تسجي باب التسجيل، بهدف “إكمال التعليم” وضمان استمرار التعليم من مرحلة رياض الأطفال إلى مرحلة الدراسات العليا.
              </p>
            </Col>

            {/* Tenth Chinese and Arabic block */}
            <Col md={6} xs={12} className="text-left" data-aos="fade-right">
              <p className="chinese-text">
                <span style={{ color: '#585e6b' }}>2005</span> 年， 5月，臺北慈濟醫院啟業，是臺灣第一座具備防震效能的醫院，以醫學中心級之服務與規模，結合慈濟人文，致力朝向社區化、人文化、資訊化。


              </p>
            </Col>

            <Col md={6} xs={12} className="text-right" data-aos="fade-left">
              <p className="arabic-text">
                <span style={{ color: '#585e6b' }}>2005</span>في شهر مايو، تم افتتاح مستشفى “تسجي تايبيه“ ، وهو أول مستشفى مقاوم للزلازل في تايوان، والذي يجمع بين الخدمات الطبية و النهج الإنساني الذي تتبعه تسجي، حيث يسعى إلى أن يقدم الخدمات العلمية والإنسانية للمجتمع.
              </p>
            </Col>

            {/* Eleventh Chinese and Arabic block */}
            <Col md={6} xs={12} className="text-left" data-aos="fade-right">
              <p className="chinese-text">
                <span style={{ color: '#585e6b' }}>2010</span>年，慈濟力行環保二十年，再倡「清淨在源頭」。
                      上人巡迴全臺「環保感恩之旅」，行腳一百四十三個環保站，向志工表達至誠的敬意，並期許志工「彎腰做環保，挺腰說環保」，宣導大眾降低慾望、減少消費，回收資源再製以節制開發，才能緩和四大不調，讓地球免於崩毀。
                      慈濟基金會獲中國大陸國務院批准，成為首家境外非營利組織成立的全國性基金會，8月20日於蘇州靜思書院聯合主持掛牌儀式，同時召開第一屆理事會。
                      慈濟基金會獲得「聯合國經濟及社會理事會非政府組織的特殊諮詢地位
                      （NGO in Special Consultative Status with ECOSOC）」。
                      </p>
            </Col>

            <Col md={6} xs={12} className="text-right" data-aos="fade-left">
              <p className="arabic-text">
                <span style={{ color: '#585e6b' }}>2010</span>, تمت الموافقة على مؤسسة تسجي من قبل مجلس الدولة وأصبحت أول مؤسسة وطنية أنشأتها منظمة غير ربحية.  حصلت مؤسسة تسجي على “الوضع الاستشاري الخاص كمنظمة غير حكومية ذات مركز استشاري خاص لدى المجلس الاقتصادي والاجتماعي التابع للأمم المتحدة.

              </p>
            </Col>

            {/* Twelfth Chinese and Arabic block */}
            <Col md={6} xs={12} className="text-left" data-aos="fade-right">
              <p className="chinese-text">
                <span style={{ color: '#585e6b' }}>2019</span>年，元月七日，國立中正大學2019年於大林慈濟醫院頒授證嚴上人
                  「中正大學名譽文學博士學位」，表彰其一生平等無差別的寬闊胸襟、慈憫萬民的無私大愛。

              </p>
            </Col>

            <Col md={6} xs={12} className="text-right" data-aos="fade-left">
              <p className="arabic-text">
                <span style={{ color: '#585e6b' }}>2019</span>في 7 يناير 2019، منحت جامعة تشونغ تشينغ الوطنية الماستر “الدكتوراه الفخرية في الآداب من جامعة تشونغ تشينغ” في مستشفى دالين تزو تشي تقديرًا لسعة أفقها وما قدمته طوال حياتها من أجل المساواة بين الناس وحبها المتفاني لجميع الناس.
              </p>
            </Col>

            {/* Thirteenth Chinese and Arabic block */}
            <Col md={6} xs={12} className="text-left" data-aos="fade-right">
              <p className="chinese-text">
                <span style={{ color: '#585e6b' }}>2020</span>年，新冠肺炎疫情四起，全球擴散，證嚴上人延續感恩與茹素的宣導，提醒眾人瘟疫與病毒是地球給予人類的警訊，要靜心省思，愛護地球生靈。

              </p>
            </Col>

            <Col md={6} xs={12} className="text-right" data-aos="fade-left">
              <p className="arabic-text">
                <span style={{ color: '#585e6b' }}>2020</span> , اندلع وباء كوفيد-19 وانتشر في جميع أنحاء العالم، واصلت الماستر العمل، مذكرة الجميع بأن الأوبئة والفيروسات هي علامات تحذيرية للبشرية، وأن عليهم التأمل وأخذ العبر, ورعاية جميع المخلوقات على وجه الأرض.
              </p>
            </Col>

            {/* Fourteenth Chinese and Arabic block */}
            <Col md={6} xs={12} className="text-left" data-aos="fade-right">
              <p className="chinese-text">
                <span style={{ color: '#585e6b' }}>2023</span>年， 國立臺灣圖書館館長曹翠英、修復師徐美文等人，攜帶修復完成的《古蘭經》古籍及複製本各一本，入靜思精舍，呈書予證嚴上人。胡光中與其雙親、余自成、謝景貴等人在座。
                                                                  上人感恩復修團隊的用心，修復珍貴古籍，並再委託其修復出家前在臺中請購的《法華經》七卷。
              </p>
            </Col>

            <Col md={6} xs={12} className="text-right" data-aos="fade-left">
              <p className="arabic-text">
                <span style={{ color: '#585e6b' }}>2023</span>, أحضر مدير مكتبة تايوان الوطنية تساو كوي ينغ، والمرمم شو ميوين وآخرون كتابًا قديمًا مرممًا للقرآن ونسخة منه إلى دار جينجسي وقدم المصحف إلى الماستر الأستاذ فيصل هو ووالديه وقدمت الماستر الشكر على هذه الهدية القيمة كما أعربت عن امتنانها لأعمال الترميم.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Biography_b;
