import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileJUDYDERBAS from './CUDI DARBAS.png';
function Page52_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
JUDYDERBAS:2006,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">JUDY DERBAS  </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.JUDYDERBAS} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileJUDYDERBAS}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我於 2014 年逃離家鄉敘利亞的戰爭來到土耳其。 
我的旅程從邊境開始，必須不定期進入土耳其，這讓旅程變得非常困難。 
在哈塔伊住了一晚後，我搬到了伊斯坦堡，在那裡和親戚住了一段時間，然後開始為我的兄弟尋找工作和自己的學校。 
我找到了滿納海學校
，它為我提供了生活支持和學習的機會，我從六年級就開始在那裡學習。 
我選擇了上人說的知足感恩這句話，因為它反映了我自己的處境，我失去了一切，意識到了珍惜身邊一切的重要性。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
وصلت إلى تركيا في عام 2014، بعد فراري من الحرب في مدينتي في سوريا. بدأت رحلتي من الحدود وكنت مضطرة للدخول إلى تركيا بطريقة غير نظامية، مما جعل الرحلة شاقة للغاية. بعد قضاء ليلة في هاتاي، انتقلت إلى إسطنبول حيث كنت أقيم لفترة مع أقاربي قبل أن أبدأ بالبحث عن عمل لأخي وعن مدرسة لنفسي. وجدت مدرسة المناهل التي قدمت لي مقعدًا دراسيًا ودعمًا معيشيًا، وبدأت التعلم فيها من الصف السادس. أخترت قول الزعيمة حول الرضا والامتنان لأنه يعكس حالتي، حيث خسرت كل شيء وأدركت أهمية التقدير لكل ما حولي.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page52_b;
