import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileRAYANHAYEK from './RAYAN HAYIK.png';
function Page41_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
RAYANHAYEK:2008,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">RAYAN HAYEK </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.RAYANHAYEK} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileRAYANHAYEK}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
2014 年 5 月，我和家人逃離敘利亞，前往北方尋求躲避殘酷戰爭的安全。 
我們抵達伊斯坦堡，在父親工作的車間住了三個晚上。 
有一次，我父親到慈濟基金會尋求幫助，那裡的志工他們給了我們急需的毯子。 
基金會幫助了我們很多，他改善了我們的生活，感謝它，最後我的父親讓我進入了滿納海第一所學校，從那時起，我就堅信孝敬父母是我們必須做的事情
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
في مايو 2014، هربت أنا وعائلتي من سوريا، متجهين شمالًا بحثًا عن الأمان من الحرب القاسية. وصلنا إلى إسطنبول وقضينا ثلاث ليالي في الورشة التي كان يعمل بها والدي. بعد فترة، وفي إحدى المرات التي كان فيها والدي يمر بجانب متطوعين من مؤسسة تسجي الخيرية، عرضوا علينا أغطية كنا في أمس الحاجة لها. هذه اللقاء كان مقدمة لتعرفنا على المؤسسة التي لعبت دورًا كبيرًا في تحسين وضعنا. بفضلها، سجلني والدي في المدرسة نجيب فاضل، ومنذ ذلك الحين، أعيش قناعة بأن بر الوالدين هو من أعظم القربات والطاعات التي يمكن أن نقدمها.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page41_b;
