import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./049.png";
import Page49_b from "./Page49_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page49() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
微笑是最祥和的語言。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Wéixiào shì zuì xiánghé de yǔyán.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
أجملُ الوجوه هي الوجوه المبتسمة.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
En güzel yüz nasıl bir yüzdür? Gülümseyen bir yüz en güzel olandır.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/EQobvYS1nwM"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/S37RDkMUkMo"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/pS0vX_eRsrg"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
تَبَسُّمُك في وَجْه أَخِيك صدقة.“ _ حديث شريف"
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
الابتسامة في الوجوه أسرع طريق إلى القلوب، وأقرب باب إلى النفوس، وهي من الخصال المتفق على استحسانها وامتداح صاحبها، وقد فطر الله الخَلْقَ على محبة صاحب الوجه المشرق البسَّام، وكان نبينا صلى الله عليه وسلم أكثر الناس تبسُّمًا، وطلاقة وجهٍ في لقاء من يلقاه، وكانت البسمة إحدى صفاته التي تحلّى بها، حتى صارت عنواناً له وعلامةً عليه، وكان لا يُفَرِّق في حُسْن لقائه وبشاشته بين الغنيّ والفقير، والأسود والأبيض، حتى أنه كان يبتسم في وجوه الأطفال ويُحسِن لقاءهم، يعرف ذلك كل من صاحبه وخالطه.
وقد جعل النبي صلى الله عليه وسلم التبسم وإظهار البشاشة والبِشْر في وجه من لقيه بأنه يؤجر الإنسان عليه كما يؤجر على الصَّدقة.
</p>
 <h5 className="turkish-text mt-4">
“Kardeşin kardeşine gülümsemesi sadakadır.“ _ Hadis-i Şerif
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
對你的兄弟微笑是一種慈善。
(聖訓)
</h5>
<p className="pinyin-text">
Duì nǐ de xiōngdì wéixiào shì yīzhǒng císhàn
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page49_b />
</section>
);
}
export default Page49;



