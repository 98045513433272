import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./053.png";
import Page53_b from "./Page53_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page53() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
心不專， 念不一，做事難以成就。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Xīn bù zhuān，niàn bù yī，zuòshì nányǐ chéngjiù.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
من دون التفكير والتخطيط لا يمكن أن يتحقق النجاح.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Zihin odaklanmamış ve düşünceler karışık olduğunda，işlerin başarılması zordur.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/QJbHuf57-4s"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/3rtPtDdz0G4"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/rEljm3fNP68"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
إنَّ اللهَ يُحِبُّ إذا عمِلَ أحدُكمْ عملًا أنْ يُتقِنَهُ.“ _ حديث شريف"
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
يُثاب المسلم على إتقان عمله ويؤجر على ذلك، لأنّ إتقان العمل وإحسانه من الأمور التي حثّت عليها الشريعة الإسلامية، وقد بين النبي صلى الله عليه وسلم أن الله يحب المتقن عمله الذي يؤدّيه على أكمل وجه.
</p>
 <h5 className="turkish-text mt-4">
“Allah, bir iş yaptığınızda onu en iyi şekilde yapmanızı ister.“ _ Hadis-i Şerif
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
的確，安拉喜愛那些做事用心努力的人。(聖訓)
</h5>
<p className="pinyin-text">
Díquè, ān lā xǐ'ài nàxiē zuòshì yòngxīn nǔlì de rén
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page53_b />
</section>
);
}
export default Page53;



