import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileMOHAMADDABAAN from './MOHAMAD DABAAN.png';
function Page1_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
MOHAMADDABAAN:2008,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">MOHAMAD DABAAN </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.MOHAMADDABAAN} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileMOHAMADDABAAN}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
2017年初，我和母親踏上了一段艱辛的旅程，穿越邊境來到了土耳其。我們的生活條件極為困難，母親四處尋找工作，為了讓我們能夠生存下去。後來，她得知滿納海學校正在招聘教師，便申請了小學教師的職位，並順利被錄取。慈濟也幫助我們租房並提供了家具。
證嚴法師的話語總是觸動我們的心靈，與我們的信仰、教義，以及我們的聖先知穆罕默德的美德完美契合。這讓我們心生感動，想透過我們的藝術創作，向證嚴法師傳達我們的美好情感以及穆斯林的美德。

</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
وصلت إلى تركيا في مطلع العام 2017 مع أمي، في رحلة شاقة عبر الحدود إلى تركية. 
وكان وضعنا المعيشي صعباً للغاية، كانت أمي تبحث عن عمل لنعيش منه فعرفت بوجود مدرسة بحاجة لمعلمين، وتم قبول أمي في (مدرسة المناهل) كمعلمة للمرحلة الابتدائية، وقد ساعدتنا (تسجي) في استئجار البيت وفرشه.
دائماً كان كلام (الزعيمة) يلامس قلوبنا وعقولنا ودائماً ما كان يطابق كلامها شرعنا الحنيف وديننا القويم وأخلاق نبينا محمد صلى الله عليه وسلم، فأحببنا أن نعبر بإنسانيتنا وديننا وأخلاقنا عما يجول في خواطرنا بلوحات مرسومة لتصل (الزعيمة) مشاعرنا الجميلة وأخلاق المسلمين.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page1_b;
