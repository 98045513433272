import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileSIDRASAADOON from './SIDRA SAADOON.png';
import profileFATIMAALZAHRAASABSABI from './FATIMA ALZAHRAA SABSABI.png';
function Page31_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
SIDRASAADOON:2007,
FATIMAALZAHRAASABSABI:2007,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">SIDRA SAADOON</strong>
</h1>
<h1 className="heading-name">
My age now: {ages.SIDRASAADOON} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileSIDRASAADOON}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
敘利亞戰爭爆發後，我們不得不從自己的城市遷移到另一個城市。但戰爭持續著，每一天的情況都在惡化，生活變得愈加困難。我們最終決定逃往土耳其尋找一個更安全的地方。
我們來到了伊斯坦布爾，聽說了滿納海學校 的存在，於是我立刻報名入學。當我被錄取的那一刻，我感到無比的喜悅和激動。
然而，我們的經濟狀況非常困難，我的姐姐和哥哥都在工廠工作，以幫助家計。在這段艱難的時期，慈濟基金會伸出了援手，不僅支持了我們，還幫助我的姐姐繼續她的學業。如今，她已經在大學裡就讀，這對我們家庭來說是一個巨大的成就。
我想藉此表達我們對證嚴法師和她所代表的愛與慈善的深深敬意。他們的幫助不僅限於敘利亞人民，也延伸到了其他受災地區，他們的善舉和無私奉獻讓世界變得更加美好。
在此，我想對慈濟基金會說：「願上天賜予你們一切美好的事物，我們對你們滿懷愛、敬意和感激之情。」
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
بعد بدء الحرب التي جرت في سوريا، نزحنا من مدينتنا إلى مدينة أخرى، لكن الحرب استمرت وكانت الأمور تزداد سوءاً كل يوم وتصبح المعيشة أصعب فاضطررنا إلى اللجوء إلى تركيا.
أتينا إلى إسطنبول فسمعنا (بمدرسة المناهل) فذهبنا وسجلت فيها وبدأت الدراسة فيها، وكم كانت فرحتي كبيرة عندما سجلت فيها. 
كان وضعنا المادي سيئاً فعملت أختي وأخي في المعامل، فقدمت لنا (مؤسسة تسجي) المساعدة وساعدت أختي لتكمل دراستها والآن هي في الجامعة.
أردت أن أعبر عن حبنا واحترمنا (للماستر) وشعبها المحب للخير والعطاء والتعاون والإنسانية، ولمساعدتها السوريين، عطاؤهم لم يقتصر على السوريين فقط بل شمل جميع بلاد المتضررة.
أقول (لمؤسسة تسجي ) جزاكم الله عنا كل الخير ولكم كل الحب والاحترام والتقدير.
</p>
</Col>
</Row>
 </Container>
 </Container>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">FATIMA ALZAHRAA SABSABI</strong>
</h1>
<h1 className="heading-name">
My age now: {ages.FATIMAALZAHRAASABSABI} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileFATIMAALZAHRAASABSABI}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">

當我在創作這幅畫時，各種情感從心底湧現。對我而言，繪畫勝過言語，而證嚴法師的話語深深地刻印在我們心中，比任何畫作都來得深刻和持久。
在那個動盪的時期，我們全家經歷了許多艱辛和挑戰。然而，當我們踏上這片陌生的土地，心中也充滿了希望和對未來的憧憬。每一筆畫筆都承載著我的思念和對家鄉的無限情感。透過繪畫，我能夠表達內心深處的想法和感受，那是言語所無法傳達的。
證嚴法師的教誨如同燈塔，指引著我們前行的方向。在她的啟發下，我們學會了如何在逆境中堅強，如何在困難面前依然心懷感恩。她的每一句話語，都成為我們生活中的力量源泉，激勵著我們不斷前進，追求心中的夢想。
這幅畫，不僅是我的心靈寫照，更是我對證嚴法師無盡感激之情的表達。通過這幅畫，我希望能將那份來自心底的溫暖和力量傳遞給每一位觀者，讓大家也能感受到那份無形但卻強大的愛與關懷。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
أتيت إلى تركيا مع عائلتي عن طريق البحر، وصلنا إلى تركيا عام 2015، بدأت عائلتي تسعى لاستكمال دراستي، كان التسجيل في المدارس متوقفاً، فسجلت في (مدرسة المناهل) بعد وصلونا بسنة واحد، وما زلت فيها حتى الآن. 
كانت مشاعر مختلفة تخرج من القلب وأنا أرسم اللوحة، فالرسم قبل الكلام، وكلام (الماستر) ارتسم في داخلنا قبل أن يرتسم بلوحة او لوحات.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page31_b;
