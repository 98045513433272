import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileSHAYMAATARIFI from './SHAYMAA WALID TARIFI.png';
function Page48_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
SHAYMAATARIFI:2012,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">SHAYMAA  TARIFI </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.SHAYMAATARIFI} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileSHAYMAATARIFI}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我們於2015年抵達土耳其，那時我父親已經先行來到這裡。剛到土耳其的時候，我們面臨了很多困難。慈濟不僅提供了財務援助，還透過診所提供了醫療支援，而最重要的是我們得到了滿納海學校的幫助，它在真主安拉的恩典下成為了我們的支柱，也成為了我們的第二個家庭。
證嚴法師是人道主義和無私奉獻的象徵，是善良的化身。她給予了我們愛與幫助，雖然她未曾見過我們，她的支持卻深深地影響了我們。我們愛她，並從她身上學到了如何成為慷慨的施與者，如何關心他人，並將善良傳遞給每一個人。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
أتينا إلى تركيا في عام 2015 بعد أن كان سبقنا أبي إلى هنا، عانينا في بداية قدومنا إلى تركيا فقدمت لنا (مؤسسة تسجي) مساعدات مالية بالإضافة إلى مساعدات طبية من قبل المستوصف، والأهم هو وجود (مدرسة المناهل) التي كانت السند لنا بعد الله ، وكانت عائلتي الثانية.
لأن (الماستر) هي رمز للإنسانية والعطاء، رمز الخير للغير، قدمت لنا الحب والمساعدة، ووقفت بجانبنا من دون أن ترانا، أحببناها وتعلمنا منها كيف نكون معطائين ونحب الخير للآخرين.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page48_b;
