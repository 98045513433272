import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./034.png";
import Page34_b from "./Page34_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page34() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
父母恩大，君子量大，小人氣大。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Fùmǔ ēn dà，jūnzǐ liàng dà，xiǎo rénqì dà.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
أعظم شيء في الدنيا هو حب الوالدين، والتسامح الذي يأتي من الإنسان النبيل.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Ebeveynlerin sevgisi büyük，asil insanların hoşgörüsü geniş，küçük insanların öfkesi fazladır.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/zn2s9SHdGU0"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/MR-VWRiAao0"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/W66ShAS7qDs"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
“ليسَ مِنَّا مَنْ لَمْ يَرحمُ صَغيرَنا، ويُوَقِّرْ كبيرَنا.“ _ حديث شريف
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
رشدنا النبي صلى الله عليه وسلم على أهمية الرفق والشفقة على الصغير والاحترام والتوقير للكبير، ومن لم يتصف بذلك فقد خالف سنة النبي صلى الله عليه وسلم.
</p>
 <h5 className="turkish-text mt-4">
“Büyüğüne saygı göstermeyen, küçüğüne merhamet etmeyen bizden değildir.“ _ Hadis-i Şerif
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
不敬長輩,，不愛小輩，非我族類。 

</h5>
<p className="pinyin-text">
Bùjìng zhǎngbèi, bù ài xiǎobèi, fēi wǒ zú lèi
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page34_b />
</section>
);
}
export default Page34;



