import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./016.png";
import Page16_b from "./Page16_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page16() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
話多不如話少，
<br />話少不如話好。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Huà duō bùrú huà shǎo，
<br />huà shào bùrú huà hǎo.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
خير الكلام أقله وألطفه.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Sözün hayırlısı az ve öz olandır.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/G-ww_lkB3Ig"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/dXH0hPdjl9Q"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/hMPqANOMK9w"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
خير الكلام ما قل ودلَّ. _ مثل عربي
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
كي تقنع غيرك بوجهة نظرك لا بد أن تكون بليغا فصيحا، تتميز عباراتك بالوضوح والبساطة، بعيدا عن التكلف والتعقيد، فكلما كانت كلماتك قليلة العدد غزيرة المعنى كان أثرها في النفوس أعمق وأبلغ. فليس للآخرين وقت طويل لسماعك، فأوجز، فالبلاغة في الإيجاز، وتجنب الإسهاب فهو مدعاة للملل والسأم.
</p>
 <h5 className="turkish-text mt-4">
En iyi söz, az ve öz olandır. _ Arap atasözü
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
最好的話語是少而善的。(阿拉伯諺語)
</h5>
<p className="pinyin-text">
Zuì hǎo de huàyǔ shì shǎo ér shàn de
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page16_b />
</section>
);
}
export default Page16;



