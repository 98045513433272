import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileWALLAALZIR from './WALAA ALZIR.png';
function Page22_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
WALLAALZIR:2009,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">WALLA AL ZIR </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.WALLAALZIR} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileWALLAALZIR}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我們經歷了敘利亞戰爭的所有情況。
之後我們去了阿聯酋，我的父親，願安拉憐憫他，在我們到達土耳其的四個月就被驅逐出境。
我們回到土耳其，我兩年沒有接受任何教育，我們的生活條件非常困難，我們什麼都沒有，不會語言，我們從零開始。
我姐姐生病了所以我們回來找慈濟義診中心，我們的旅程從這裡開始
，慈濟基金會開始透過 BIM 卡援助我們，然後我在四年級時在學校註冊了。
我要向證嚴法師表達我的感恩，自從我們來到一個陌生的新國度以來，您幫助我們度過了磨難，因為您，我完成了我一直夢想的教育。
當我看到靜思語的文字時，我覺得她的文字蘊含著一種美好精神，應該以一種大家都能看到的形式來表達，而這件事卻真實地發生了，我把我認為最有表現力的一句話畫了出來。
感恩您的這一句靜思語，他代表著豐富而美好的感情。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
عشنا كل ظروف الحرب في سوريا، تم قصف بيتنا بعد خروجنا منه بعشرة دقائق ودمر بالكامل، دخلنا إلى تركيا من باب السلامة. 
ذهبنا إلى دولة الامارات وتم ترحيل والدي رحمه الله إلى تركيا بعد وصولنا بأربعة أشهر. 
عدنا إلى تركيا وبقينا دون تعليم سنتين، كنا نعيش بظروف صعبة جداً لا نملك أي شيء، لا يوجد عندنا لغة، بدأنا من الصفر. 
كانت أختي مريضة فراجعنا (مستوصف تسجي) ومن هنا بدأت رحلتنا مع (مؤسسة تسجي) ثم بدأت المساعدات تصل لنا عبر كروت البيم، ومن ثم سجلت في المدرسة في الصف الرابع. 
أريد أن أعبر عن امتناني وشكري (للماستر) الذي ساعدنا في محنتنا منذ وصولنا إلى بلد جديد وغريب وبسببه أتممت تعليمي الذي لطالما حلمت به. 
وعندما كنت أرى كلامها مكتوباً على المجلات كنت أحس أن كلامها فيه روح جميلة مخفية ويجب إخراجه على شكل يراه الجميع، وتم بالفعل هذا الشيء ورسمت لوحة معبرة عن جملة كنت أراها أجمل شيء. 
شكرا لوجود هذه العبارة الغنية بالعبر والإحساس الجميل..
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page22_b;
