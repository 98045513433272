import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./05.png";
import Page5_b from "./Page5_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page5() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
現在分分秒秒的付出，
<br />就是未來點點滴滴的收穫。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Xiànzài fēn fēn miǎo miǎo de fùchū，
<br />jiùshì wèilái diǎn diǎndī dī de shōuhuò.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
استثمار الوقت ولو قليلاً يعطينا ثماراً نحصد نتائجها مستقبلاً.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
u anda harcadığınız her emek，gelecekte misliyle meyvesini verecektir.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/0dcTZJEb7mU"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/PcLAQzU5c9w"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/6I3eHKv5KI0"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
السر ليس في قضاء الوقت بل في استثماره. _ مثل عربي
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
قيمة الوقت: تشير العبارة إلى أن الوقت هو رأس مال ثمين محدود، وليس مجرد مروره تعني أننا نملكه، بل يجب أن يكون هناك وعي بكيفية استغلاله بشكل فعّال لتحقيق الأهداف.
الفرق بين “قضاء“ و“استثمار“:
قضاء الوقت: يعني قضاء الوقت في الأنشطة اليومية دون وعي أو تخطيط، وقد يرتبط بالهدر أو الاستخدام الضائع للوقت.
استثمار الوقت: يعني استخدام الوقت بشكل مدروس وبناء، بحيث يتم تخصيصه للأنشطة التي تضيف قيمة، مثل التعلم، العمل على الأهداف الشخصية أو المهنية، أو حتى تنمية العلاقات.
الأهداف والاستراتيجيات: يشير الاستثمار في الوقت إلى أهمية تحديد الأهداف ووضع استراتيجيات للوصول إليها. باستخدام الوقت بشكل واعٍ، يمكن للشخص أن يحقق إنجازات أكبر في حياته.
الخلاصة: تدعونا العبارة للتركيز على كيفية استخدام الوقت بفاعلية وبطريقة تضيف قيمة لحياتنا. بدلاً من الانتظار أو التشتت في أنشطة غير مفيدة، يجب أن نتخذ خيارات واعية تعزز من جودة حياتنا ونجاحنا. إن استثمار الوقت بشكل مدروس يمكن أن يؤدي إلى تحقيق الأهداف والأحلام بشكل أكثر فعالية ونجاحاً.
</p>
 <h5 className="turkish-text mt-4">
İşin sırrı zaman harcamak değil, verim almaktır. _ Arap atasözü
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
成功秘訣在於如何利用時間而不浪費它。
（阿拉伯諺語）
</h5>
<p className="pinyin-text">
Chénggōng mìjué zàiyú rúhé lìyòng shíjiān ér bù làngfèi tā
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page5_b />
</section>
);
}
export default Page5;



