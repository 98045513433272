import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./051.png";
import Page51_b from "./Page51_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page51() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
環保小小兵，垃圾變黃金。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Huánbǎo xiǎo xiǎobīng，lèsè biàn huángjīn.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
نحن رواد في إعادة التدوير، يمكننا تحويل القمامة إلى ذهب.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Biz geri dönüşüm öncüleriyiz，çöpleri altına çevirebiliriz.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/5ZNDPGOkAW4"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/QXw1tv5Xf1M"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/AR-ZYD8VahQ"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
الحكمة في استخدام البقايا تبني الثروة. 
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
تحمل العبارة معاني عميقة حول كيفية التعامل مع الموارد المتاحة لدينا، وكيفية تحقيق الاستفادة القصوى منها:
الشرح والمعاني:
- الفهم العميق للقيمة: تشير هذه العبارة إلى أن هناك قيمة في الأشياء التي قد يعتبرها البعض غير ذات قيمة، مثل بقايا الطعام أو المواد الزائدة. عن طريق الاستخدام الذكي لهذه البقايا، يمكن تحويل ما يبدو غير مفيد إلى موارد قيمة.
- الاستدامة: تعكس الحكمة في إعادة استخدام البقايا فكرة الاستدامة، حيث يمكن تقليل الهدر وزيادة الاستفادة من الموارد المحدودة. هذا النهج يساهم في حماية البيئة والموارد الطبيعية.
- الإبداع والابتكار: استخدام البقايا يتطلب التفكير الإبداعي والابتكار. قد يُوجب علينا البحث عن طرق جديدة للاستفادة مما لدينا، مما يمكن أن يؤدي إلى أفكار جديدة أو حلول لمشكلات موجودة.
- المسؤولية الاقتصادية: تُعزز العبارة فكرة المسؤولية المالية، حيث يساعد الاستخدام الذكي للبقايا في تقليل النفقات وزيادة المدخرات. بالتالي، يُمكن بناء الثروة من خلال اتخاذ قرارات مالية حكيمة.
- بناء الثروة: الثروة هنا لا تعني فقط المال، بل يمكن أن تشير أيضًا إلى الثروة المعنوية والعائلية والاجتماعية التي يمكن أن تُبنى من خلال استخدام الموارد بذكاء. يمكن أن يكون الاستثمار في الناس والأسرة والمجتمع جزءًا من بناء “الثروة“ الحقيقية.
</p>
 <h5 className="turkish-text mt-4">
Atıkları akıllıca kullanmak bir servet inşa eder
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
明智地利用垃圾可以累積財富。
(阿拉伯諺語)
</h5>
<p className="pinyin-text">
Míngzhì dì lìyòng lèsè kěyǐ lěijī cáifù
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page51_b />
</section>
);
}
export default Page51;



