import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./023.png";
import Page23_b from "./Page23_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page23() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
不要輕視簡單不起眼的事，
<br />複雜困難的事是許多簡單的總和。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Bùyào qīngshì jiǎndān bù qǐyǎn de shì， 
<br />fùzá kùnnán de shì shì xǔduō jiǎndān de zǒnghé.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
لا تستخفَّ بالمهام البسيطة لأنها تصنعُ النتائجَ العظيمة.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Basit ve önemsiz görünen işleri küçümsemeyin; 
karmaşık ve zor işler birçok basit işin toplamıdır.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/0ZMOWWveyyw"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/EkjlkIE_-Ks"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/IDX-QFgmZWA"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
أول الغيثِ قطرة. _ مثل عربي
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
المعنى الكلي: تشير العبارة إلى أن كل شيء كبير يبدأ بخطوات أو أمور صغيرة. فعلى سبيل المثال، أي مشروع أو فكرة أو تغيير يبدأ عادة بخطوة صغيرة جداً، ولكن تلك الخطوة يمكن أن تؤدي إلى نتائج كبيرة وملحوظة فيما بعد.
العبرة: تُشجع هذه العبارة على أهمية الاستمرارية والثبات في أي مسعى، وأن البداية المتواضعة لا تقلل من القيمة أو الأهمية. كما تذكّر الناس أن الوقت والمثابرة يمكن أن يؤديان إلى نتائج إيجابية، حتى لو كانت البداية بسيطة.
</p>
 <h5 className="turkish-text mt-4">
Yağmurun başlangıcı bir damladır. _ Arap atasözü
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
傾盆大雨來自小小水滴。（阿拉伯諺語）
</h5>
<p className="pinyin-text">
Qīngpén dàyǔ láizì xiǎo xiǎo shuǐdī
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page23_b />
</section>
);
}
export default Page23;



