import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileALAAALLTFO from './ALAA ALLTFO .png';
function Page24_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
ALAAALLTFO:2004,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name"> ALAA ALLTFO  </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.ALAAALLTFO} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileALAAALLTFO}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
2014年五月，我們來到了土耳其，這是一次艱難而不幸的旅程，我們還沒有準備好離開我們的家、我們的親人和我們的國家。
那天是學校要發成績單的日子，也是我生日的前一天，我沒有向父親請求讓我們多留一天。
我只是哭著，盯著我所愛的東西作為告別，房子的花園，我們的貓，前面的草坪，牆壁和一切。
我在土耳其學校上學，我不懂土耳其語，並遭受霸凌。當獲得機會可以在滿納海學註冊，這成了一條重生的路
生活開始充滿希望，我們是奮鬥者，努力著生存，帶著希望前進。 
讚美主，謝謝你 慈濟基金會，謝謝你證嚴法師謝謝你，全世界的慈濟人
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
في عام 2014 في الشهر الخامس أتينا إلى تركيا، كانت رحلة شاقة ومؤسفة، لم نكن على استعداد لمفارقة بيتنا وأحبتنا وبلدنا.
كان ذات اليوم الذي سيتم به استلام الجلاءات في المدرسة، و قبل يوم ميلادي بيوم، لم أطلب من أبي أن نبقى يوماً آخراً. 
اكتفيت بالبكاء والتحديق بالأشياء التي أحبها كنوع من الوداع حديقة المنزل، قططنا، الحديقة الأمامية والجدران وكل شيء. 
ذهبت إلى المدارس التركية كنت لا أفهم اللغة التركية وأعاني من التنمر. فسجلت في (مدرسة المناهل) عندما تم ترخيصها التي كانت بمثابة طوق نجاة.
الحياة مليئة بالأمل، كل المصاعب ليست باقية، نحن الجوهر ونحن الذين نكافح ونحافظ على بقائنا ونتقدم بأمل. . بثقة، وبتفاؤل وإيمان وعزيمة وجد. 
الحمد للَّه شكراً تسجي شكراً للماستر شكراً أستاذ فيصل وأستاذة نادية
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page24_b;
