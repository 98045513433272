import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./046.png";
import Page46_b from "./Page46_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page46() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
天上最美是星星，人間最美是溫情。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Tiānshàng zuìměi shì xīngxīng，rénjiān zuìměi shì wēnqíng.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
النجوم أجمل ما في السماء، والحب والرعاية هما أجمل شيء على الأرض.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Gökyüzündeki en güzel şey yıldızlardır，yeryüzündeki en güzel şey ise sevgi ve şefkattir.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/14UIup8lQCM"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/qDSmbByjocc"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/ei8G3FmNK1Q"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
لا يُؤْمِنُ أحدُكُم حتى يُحِبَّ لِأَخِيهِ ما يُحِبُّ لنفسِهِ. _ حديث شريف
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
حرص الإسلام بتعاليمه وشرائعه على تنظيم علاقة الناس بعضهم ببعض؛ حتى تسود الألفة والمحبة في المجتمع المسلم، ولا يتحقق ذلك إلا إذا حرص كل فرد من أفراده على مصلحة غيره حرصه على مصلحته الشخصية، وبذلك ينشأ المجتمع الإسلامي قويّ الروابط، متين الأساس.
ومن أجل هذا الهدف، بيّن النبي صلى الله عليه وسلم أن من أهم عوامل رسوخ الإيمان في القلب، أن يحب الإنسان حصول الخير للآخرين كالذي يحبه لنفسه، من حلول النعم وزوال النقم، وبذلك يكمل الإيمان في القلب.
</p>
 <h5 className="turkish-text mt-4">
“Sizden biri kendisi için istediğini, kardeşi için de istemedikçe gerçekten mümin olamaz.“ _ Hadis-i Şerif
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
正信的人愛自己的兄弟如愛自己一般
(聖訓)
</h5>
<p className="pinyin-text">
Zhèngxìn de rén ài zìjǐ de xiōngdì rú ài zìjǐ yībān
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page46_b />
</section>
);
}
export default Page46;



