import React, { useState } from "react";
import { useLocation } from "react-router-dom"; 
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../Assets/logo.png";

import { AiOutlineHome, AiOutlineFundProjectionScreen } from "react-icons/ai";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const location = useLocation();

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  const hideButtons = location.pathname === "/Dedication" || location.pathname === "/Home"|| location.pathname === "/";

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Brand href="/" className="d-flex">
          <img src={logo} className="img-fluid logo" alt="brand" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            {!hideButtons && (
              <Nav.Link 
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  updateExpanded(false);
                }} 
                style={{ color: "#395125" }}
              >
                <AiOutlineHome style={{ marginBottom: "2px", color: "#395125" }} /> Home
              </Nav.Link>
            )}

            {!hideButtons && (
              <Nav.Item>
                <Nav.Link href="#about-section" 
                  onClick={() => updateExpanded(false)} 
                  style={{ color: "#395125" }}>
                  <AiOutlineFundProjectionScreen 
                    style={{ marginBottom: "2px", color: "#395125" }} /> My Story
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
