import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./029.png";
import Page29_b from "./Page29_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page29() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
不要小看自己，
<br />人有無限的可能。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Bùyào xiǎo kàn zìjǐ，
<br />rén yǒu wúxiàn de kěnéng.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
لا تقلل من شأن نفسك، فأنت تملِك إمكانيات غير محدودة.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Kendini küçümseme，insanın sınırsız potansiyeli vardır.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/5hlIIi7bbdc"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/LQeEJ7C68BY"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/tHz0vtrOZ8Y"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
“لا يَحقِرَنَّ أحَدُكم نَفْسَه.“ _ حديث شريف"
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
التدبر في الحديث..
تعزيز الثقة: الحديث يشجع على تحفيز الثقة بالنفس وتقدير القيم الفردية، مما يساعد الأفراد على اتخاذ خطوات إيجابية نحو تحقيق أحلامهم وأهدافهم.
التفاعل الاجتماعي: يتضمن الحديث دعوة إلى الاهتمام بالآخرين ودعمهم في بناء ثقتهم بأنفسهم، مما يُعزّز من روح التعاون والمساندة في المجتمع.
الخلاصة..
“لا يحقِرنَّ أحدُكم نفسه“ هو دعوة إلى كل إنسان بأن يقدّر نفسه ولا يقلل من شأنها، ويُذكره بأن لديه القدرة على تحقيق النجاح والتأثير الإيجابي، بغض النظر عن التحديات التي قد يواجهها. إن تعزيز احترام الذات والثقة بالنفس هو جانب أساسي في حياة كل فرد، مما يمكّنه من الإسهام الفعال في مجتمعه.
</p>
 <h5 className="turkish-text mt-4">
“Hiçbiriniz kendinizi küçümsemeyin“ _ Hadis-i Şerif
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
你們誰都不該貶低自己。
(聖訓)
</h5>
<p className="pinyin-text">
Nǐmen shuí dōu bù gāi biǎndī zìjǐ
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page29_b />
</section>
);
}
export default Page29;



