import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileSIMAKHAROOF from './sima kharoof.png';
function Page26_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
SIMAKHAROOF:2009,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">SIMA KHAROOF </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.SIMAKHAROOF} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileSIMAKHAROOF}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
從2013年以來，我全家人飽受敘利亞戰亂之苦，因此來到土耳其避難。 
我和我的父親一起到達，他比我們先過來這裡，並住在我叔叔的家裡，直到我父親能夠在土耳其找到一所房子並接我們過來，土耳其。 
起初生活很困難，但隨著時間的推移，一切都變得更好了。 
我在滿納海學校註冊了，在那裡我得到了支持和幫助
我非常感謝證嚴法師給了我們這麼多，我想透過畫一幅圖畫來表達我們對自己健康的關心和對父母的照顧，讓上人的話永垂不朽。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
منذ 2013، عشت تحت معاناة الحرب في سوريا، مما دفعنا للجوء إلى تركيا. وصلت مع والدي الذي كان قد سافر قبلنا، وأقمت في منزل خالي حتى استطاع والدي تأمين منزل في تركيا وجلبنا إليه. الحياة كانت صعبة في البداية، لكن تحسنت مع الوقت. التحقت بمدرسة المناهل حيث وجدت الدعم والتقدير. 
أشعر بالامتنان للزعيمة التي قدمت لنا الكثير، وأود تخليد كلماتها من خلال رسم لوحة تعبر عن اهتمامنا بصحتنا ورعاية والدينا
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page26_b;
