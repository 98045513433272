import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileHALAALALİ from './HALA MOHAMAD ALI.png';
function Page45_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
HALAALALİ:2008,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">HALA AL ALİ </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.HALAALALİ} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileHALAALALİ}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我們於2016年抵達土耳其，為了尋求更安全和更美好的生活。

在經歷了數月的艱辛和疲憊後，我們終於來到了伊斯坦堡。

然而，由於生活條件困難，我們不得不在工地工作，放棄了學業。

直到聽說了慈濟基金會，在他們的幫助下，我重新回到了學校，這是一段難以忘懷的快樂時光。

我創作這幅畫以表達我對證嚴法師的愛和感激之情。
她給了我們重返學校的機會，是我們在離開家鄉後，艱難時期唯一幫助過我們的人。
選擇這些話語作為最佳表達方式，將它們化為一幅充滿生命力的畫作。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
وصلنا إلى تركيا عام 2016 باحثين عن حياة أفضل يتوفر فيها الأمان، بعد عناء وتعب استمر لأشهر، بعد وصولنا إلى إسطنبول وبسبب الظروف المعيشية الصعبة قررنا أن نعمل في الورشات وترك الدراسة، حتى سمعنا عن (مؤسسة تسجي الخيرية) وعدت إلى الدراسة بعد مساعدتهم لي وكانت لحظات من الفرحة لا تنسى. 
أرسم اللوحة لأعبر عن حبي وتقديري (للماستر) التي منحتنا فرصة العودة إلى الدراسة، وهي الوحيدة التي ساعدتنا في الأيام الصعبة التي مرت علينا بعد خروجنا من بلدنا، وكان أفضل تعبير عن ذلك اختيار هذه الكلمات لأحولها إلى لوحة تنبض بالحياة.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page45_b;
