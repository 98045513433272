import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileJESSICAMUSTAFA from './JESSICA MUSTAFA .png';
function Page17_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
JESSICAMUSTAFA:2006,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">JESSICA MUSTAFA </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.JESSICAMUSTAFA} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileJESSICAMUSTAFA}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我們住在阿勒頗當戰爭開始蔓延到我的國家時，我們逃到村莊，然後來到土耳其避難，但我們聽說有困難的情況等著我們。
慈濟基金會，它支持了我們，讓我們擺脫了困境，並為我們提供了學習的機會。
滿納海學校甚至提供給我們每個月的補助我選擇這證嚴法師這一句靜思語句話是現實的、美好的，因為我們必須時時謹言慎行，做個好人，永遠對別人懷有一顆善心
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
كنا مقيمين بمدينة حلب عندما بدأت الحرب تزحف إلى بلدي، نزحنا إلى القرى ثم لجأنا إلى تركيا، أتينا إلى إسطنبول ولكن ظروفاً صعبة كانت في انتظارنا، سمعنا (بمؤسسة تسجي) التي ساندتنا وخففت عنا ما نحن فيه، وقدمت لنا مقاعد دراسية في (مدرسة المناهل) بالإضافة لهدايا شهرية. 
إنّ كلام (الماستر) واقعي وجميل، اخترتُ هذه العبارة لأننا دائماً يجب أن نحرص على أفعالنا وأقوالنا ويجب أن نكون أشخاصاً جيدين وأن نملك قلباً طيباً دائماً مع الآخرين.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page17_b;
