import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";

function Dedication() {

  useEffect(() => {
    AOS.init({ duration: 1200, once: false });
  }, []);

  return (
    <section>
      <Container fluid className="home-about-section" id="about">
        <Container>
          <Row className="justify-content-center">
            <Col
              md={6}
              className="home-about-description d-flex justify-content-start align-items-center flex-column text-center"
              data-aos="fade-right"
              style={{ minHeight: "100vh" }} // Ensuring the column takes full viewport height
            >
              <p className="home-about-body chinese-text" style={{ marginBottom: "10px" }}>
                奉獻精神
              </p>
              <p className="home-about-body chinese-text">
                "敬愛的 證嚴上人.. 您是 愛的泉源和希望之花..
                多年來，您的話語一直是我們前進道路上的燈塔。 您的一句話可以讓我們堅持向善。 您的一句話給我們的工作產生了很大的影響。
                我們將這本書和這些繪畫作品獻給您和世界各地的 所有慈濟人。 這本書裏面的插畫是老師學生們與您的智慧話語 互動的結果。
                您的話語感動了我們，讓我們心中充滿大愛。
                請接受我們最大的愛和尊重。"
              </p>
            </Col>

            <Col
              md={6}
              className="home-about-description d-flex justify-content-center align-items-center flex-column text-center"
              data-aos="fade-left"
              style={{ minHeight: "100vh" }}
            >
              <p className="home-about-body arabic-text" style={{ marginBottom: "10px" }}>
                الإهداء
              </p>
              <p className="home-about-body arabic-text">
                هذا الكــتاب وهذه اللوحــــات التي كانت نتاج تفاعلنا مع كلماتكم الحكيمة.
                كلماتكم التي حركت مشاعرنا المفعمة بالحب والوفاء لأصحاب الوفاء..
                تقبلوا فائق حبنا، تقديرنا واحترامنا.
                لماستر تشن يان..
                ينبوع المحبة وزهرة الأمل..
                <br />
                لقد كانت كلماتكم على مدى سنوات طويلة منارة ودليلاً لنا في طريقنا.
                <br />
                إن لكلماتكم كبير الأثر في مثابرتنا وجدنا وعملنا.
                إننا نهدي لكم ولكل متطوعي تســـجي في العالم.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Dedication;
