import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileFALAKSALAME from './FALAK SALAME .png';
function Page10_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
FALAKSALAME:2006,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">FALAK SALAME </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.FALAKSALAME} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileFALAKSALAME}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我們於2016年偷渡抵達土耳其，那是一條艱辛的道路。
我們到達伊斯坦堡時一無所有，對此地一無所知
 滿納海學校為我們提供學習還有每月的補助
我選擇這句話是因為這是我生活中遵循的原則之一
我做所有事情都小心謹慎，所以我希望其他也這樣做，我畫這幅畫是為了看到它的人的注意自己身邊的小事
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
وصلنا إلى تركيا عام 2016 بطريقة غير شرعية، كان طريق صعباً وشاقاً.
وصلنا إلى إسطنبول لانملك أي شيء ولا نعلم عنها شيئاً، أقمنا فترة مع أحد الأقارب، ومن ثم بدأت رحلة البحث عن منزل وعمل، وجدنا عملاً لإخوتي، وبعدها بحثنا عن مدرسة، وكانت (مدرسة المناهل) فقدمت لنا مقاعد دراسية وهدايا شهرية.
اخترت هذا القول لأنه أحد المبادئ التي أسير عليها في حياتي فأنا لا أقلق بشأن أي شيء، وأحرص على فعل جميع الاشياء بحذر وانتباه، فأردت أن يسير عليه غيري فرسمت اللوحة لألفت انتباه من يشاهدها ليعمل بها.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page10_b;
