import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileLEYLAMUKAYED from './LEYLA MUKAYED.png';
function Page46_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
LEYLAMUKAYED:2008,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">LEYLA MUKAYED</strong>
</h1>
<h1 className="heading-name">
My age now: {ages.LEYLAMUKAYED} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileLEYLAMUKAYED}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我們在2016年歷經漫長而艱辛的旅程來到土耳其，在路途中遭遇了搶劫，並被迫滯留在路上三天。
我們的艱難旅程似乎沒有結束，家庭經濟狀況非常困窘。父母努力嘗試讓我進入土耳其的學校，但由於學業中斷了太長時間，他們的努力未能成功。幸運的是，我們得知了滿納海學校的存在，於是註冊了那裡。感謝學
校在我們最需要的時候，為我們敞開了大門，讓我們獲得了學習的機會，並提供了無私的支持。
滿納海學校充滿了愛與奉獻之情。我衷心希望有一天能夠回報他們為我們所付出的一切，這份感恩之情是我們對學校及其創辦人最真摯的敬意。
畫這幅畫對我來說是一個微不足道的小小感謝，是對證嚴法師的恩情和付出表達感激之情，因為她的愛心和奉獻如同真主安拉賜予我們的祝福。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
جئنا إلى تركيا بعد مشقة طويلة في الطريق عام 2016، تعرضنا إلى السرقة وتُرِكنا في الطريق مدة ثلاثة أيام. 
بدأت رحلة أخرى من الصعوبة، كان الوضع سيئاً من الحالة المادية، حاول أهلي تسجيلي في المدراس التركية لكنهم لم يوفقوا بسبب انقطاعنا الطويل عن الدراسة، فعلمنا بوجود (مدرسة المناهل) فسجلنا فيها، فشكراً لها لأنها كانت عوناً لنا لأخذ العلم، وفتحت أبوابها أمامنا عندما لم يسجلنا أحد وقدمت لنا الدعم. 
(مدرسة المناهل) مدرسة مليئة بالحب والعطاء، وأتمنى أن أرد الجميل الذي بذلوه لأجلنا الذي يعبر عن مدى حبنا للمدراسة ومؤسسيها. 
كان رسم لهذه اللوحة بمثابة شكر صغير جداً (للماستر) لفضلها وعطائها بعد الله سبحانه.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page46_b;
