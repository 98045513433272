import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileFATIMAALOMAR from './FATMA ALOMAR.png';
function Page43_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
FATIMAALOMAR:2008,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">FATIMA AL OMAR </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.FATIMAALOMAR} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileFATIMAALOMAR}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
2014年3月5日，我前往土耳其避難，以躲避我國的戰爭。 
由於必須逃跑，旅途充滿危險和疲憊。 
我父親在慈濟基金會工作，透過他的工作幫助我們實現了穩定的生活。 
我透過父親認識了滿納海學校並進入了五年級。 
證嚴法師這句話引起了我對照顧好自己作為回饋和表達對父母的感激之情，這促使我選擇這句話來畫，因為它反映了我的生活狀況和我對他人的感激之情。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
في الخامس من مارس 2014، اضطررت للجوء إلى تركيا هربًا من الحرب الشديدة في بلدي. كانت الرحلة محفوفة بالمخاطر ومرهقة بسبب الاضطرار للهروب. والدي، الذي عمل في جمعية، ساعدنا في تحقيق استقرار متوسط من خلال عمله. تعرفت على مدرسة المناهل من خلاله وسجلت في الصف الخامس. لفتت انتباهي عبارة الزعيمة عن أهمية الاعتناء بأنفسنا كوسيلة لرد الجميل والتعبير عن الامتنان، مما دفعني لاختيار هذه العبارة لرسمها لأنها تعكس الحالة التي عشتها والامتنان الذي أشعر به تجاه الجمعية التي ساندتنا.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page43_b;
