import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./07.png";
import Page7_b from "./Page7_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page7() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
多做多得，少做多失。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Duō zuò duō dé，shǎo zuò duō shī.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
العمل الكثير يربحك الكثير والعمل القليل يخسرك الكثير .
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Çok çalışmak çok kazandırır，az çalışmak çok kaybettirir.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/_v_X9FCBE6M"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/TO54u2eXhMY"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/tttQOkEuwEY"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
من جد وجد ومن زرع حصد. _مثل عربي
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
المعنى الكلي: العبارة تعبر عن مبدأ أنه لا بد من العمل الجاد والاجتهاد لتحقيق النجاح، فهي تحفّز الأشخاص على عدم الاستسلام والاعتماد على الفعل والعمل لتحقيق ما يريدونه.
العبرة: تتناول الفكرة أن العمل الجاد هو المفتاح لكل نجاح، وأن الثمار تأتي فقط من خلال الجهد والإخلاص، كما تشجّع هذه العبارة الأفراد على التحلي بالعزيمة والمثابرة، وتأكيد أهمية قيمة العمل والجد.
</p>
 <h5 className="turkish-text mt-4">
Emek tohumu eken, mutlaka mükafatını alacaktır. _ Arap atasözü
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
誰努力誰得到，誰播種誰收獲。(阿拉伯諺語)
</h5>
<p className="pinyin-text">
Shuí nǔlì shuí dédào, shuí bōzhòng shuí shōuhuò
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page7_b />
</section>
);
}
export default Page7;



