import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./012.png";
import Page12_b from "./Page12_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page12() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
做好事不能少我一人，
<br />做壞事不能多我一人。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Zuò hǎoshì bùnéng shǎo wǒ yīrén， 

<br />zuò huàishì bùnéng duō wǒ yīrén.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
يجب أن نشارك في كل خير، وأن نبتعد عن كل شر.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Her iyilikte yer almalıyız ve her kötülükten uzak durmalıyız.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/VbDsqCDfSIw"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/-J6HoWHoCIE"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/kHgUDlvtbsc"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
“وَتَعَاوَنُوا عَلَى الْبِرِّ وَالتَّقْوَى وَلا تَعَاوَنُوا عَلَى الإثْمِ وَالْعُدْوَانِ.“ _ آية كريمة“
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
يأمر الله عز وجل عباده المؤمنين بالتعاون على البر وهو كل خير أمر به الشرع أو اطمأن إليه القلب، وينهاهم عن التعاون على الإثم وهو الذنب والمعصية وكل ما منعه الشرع أو حاك في الصدر وكره فاعله أن يطلع عليه الناس، وكذلك ينهاهم عن التعاون على التعدي على حقوق الغير.
</p>
 <h5 className="turkish-text mt-4">
“İyilik ve takva üzerine yardımlaşın, birbirinizle yarışın;
günah ve zulüm üzerine değil.“ _ Ayet-i Kerime
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
你們當為正義和敬畏而互助
不要為罪惡和過份而相幫。
(古蘭經第五章第二節）
</h5>
<p className="pinyin-text">
Nǐmen dāng wèi zhèngyì hé jìngwèi ér hùzhù 
bùyào wèi zuì'è héguò fèn ér xiāng bāng
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page12_b />
</section>
);
}
export default Page12;



