import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileTESNIMATTER from './TASNIM ATAR.png';
function Page37_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
TESNIMATTER:2008,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">TESNIM ATTER </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.TESNIMATTER} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileTESNIMATTER}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
當我到達伊斯坦堡並到滿納海學校就讀後，我們有機會學習阿拉伯語，我發現人類的情感是塑造我的個性和我的的靈感來源。 
慈濟基金會是我的家人，它給我的支持，它是我的知識和道德的來源。
我想透過這次參與繪製一幅傳達智慧意義的畫來表達我的謝意。
這所學校讓我學習伊斯蘭教的道理，讓我不忘本
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
عند وصولي إلى إسطنبول، وبعد الانتقال إلى مدرسة المناهل التي منحتنا فرصة التعلم باللغة العربية، وجدت في المشاعر الإنسانية مصدر إلهام لتشكيل شخصيتي وأسلوبي في التعامل مع الحياة. لقد كانت مدرسة المناهل عائلتي وسندي وينبوع العلم والأخلاق.
وقد أردت أن أعبر عن امتناني من خلال هذه المشاركة في رسم لوحة تنقل معاني الحكمة. 
المدرسة شبهت لي بمبادئ الإسلام، وكانت ملاذي ومنبع تعلمي.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page37_b;
