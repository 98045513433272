import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home/Home";
import Biography from "./components/Biography/Biography";
import Dedication from "./components/Dedication/Dedication";



// start pages
import Page1 from "./components/pages/page1/Page1";
import Page2 from "./components/pages/page2/Page2";
import Page3 from "./components/pages/page3/Page3";
import Page4 from "./components/pages/page4/Page4";
import Page5 from "./components/pages/page5/Page5";
import Page6 from "./components/pages/page6/Page6";
import Page7 from "./components/pages/page7/Page7";
import Page8 from "./components/pages/page8/Page8";
import Page9 from "./components/pages/page9/Page9";
import Page10 from "./components/pages/page10/Page10";
import Page11 from "./components/pages/page11/Page11";
import Page12 from "./components/pages/page12/Page12";
import Page13 from "./components/pages/page13/Page13";
import Page14 from "./components/pages/page14/Page14";
import Page15 from "./components/pages/page15/Page15";
import Page16 from "./components/pages/page16/Page16";
import Page17 from "./components/pages/page17/Page17";
import Page18 from "./components/pages/page18/Page18";
import Page19 from "./components/pages/page19/Page19";
import Page20 from "./components/pages/page20/Page20";
import Page21 from "./components/pages/page21/Page21";
import Page22 from "./components/pages/page22/Page22";
import Page23 from "./components/pages/page23/Page23";
import Page24 from "./components/pages/page24/Page24";
import Page25 from "./components/pages/page25/Page25";
import Page26 from "./components/pages/page26/Page26";
import Page27 from "./components/pages/page27/Page27";
import Page28 from "./components/pages/page28/Page28";
import Page29 from "./components/pages/page29/Page29";
import Page30 from "./components/pages/page30/Page30";
import Page31 from "./components/pages/page31/Page31";
import Page32 from "./components/pages/page32/Page32";
import Page33 from "./components/pages/page33/Page33";
import Page34 from "./components/pages/page34/Page34";
import Page35 from "./components/pages/page35/Page35";
import Page36 from "./components/pages/page36/Page36";
import Page37 from "./components/pages/page37/Page37";
import Page38 from "./components/pages/page38/Page38";
import Page39 from "./components/pages/page39/Page39";
import Page40 from "./components/pages/page40/Page40";
import Page41 from "./components/pages/page41/Page41";
import Page42 from "./components/pages/page42/Page42";
import Page43 from "./components/pages/page43/Page43";
import Page44 from "./components/pages/page44/Page44";
import Page45 from "./components/pages/page45/Page45";
import Page46 from "./components/pages/page46/Page46";
import Page47 from "./components/pages/page47/Page47";
import Page48 from "./components/pages/page48/Page48";
import Page49 from "./components/pages/page49/Page49";
import Page50 from "./components/pages/page50/Page50";
import Page51 from "./components/pages/page51/Page51";
import Page52 from "./components/pages/page52/Page52";
import Page53 from "./components/pages/page53/Page53";




// end
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Biography" element={<Biography />} />
          <Route path="/Dedication" element={<Dedication />} />

          <Route path="/Page1" element={<Page1 />} />
          <Route path="/Page2" element={<Page2 />} />
          <Route path="/Page3" element={<Page3 />} />
          <Route path="/Page4" element={<Page4 />} />
          <Route path="/Page5" element={<Page5 />} />
          <Route path="/Page6" element={<Page6 />} />
          <Route path="/Page7" element={<Page7 />} />
          <Route path="/Page8" element={<Page8 />} />
          <Route path="/Page9" element={<Page9 />} />
          <Route path="/Page10" element={<Page10 />} />
          <Route path="/Page11" element={<Page11 />} />
          <Route path="/Page12" element={<Page12 />} />
          <Route path="/Page13" element={<Page13 />} />
          <Route path="/Page14" element={<Page14 />} />
          <Route path="/Page15" element={<Page15 />} />
          <Route path="/Page16" element={<Page16 />} />
          <Route path="/Page17" element={<Page17 />} />
          <Route path="/Page18" element={<Page18 />} />
          <Route path="/Page19" element={<Page19 />} />
          <Route path="/Page20" element={<Page20 />} />
          <Route path="/Page21" element={<Page21 />} />
          <Route path="/Page22" element={<Page22 />} />
          <Route path="/Page23" element={<Page23 />} />
          <Route path="/Page24" element={<Page24 />} />
          <Route path="/Page25" element={<Page25 />} />
          <Route path="/Page26" element={<Page26 />} />
          <Route path="/Page27" element={<Page27 />} />
          <Route path="/Page28" element={<Page28 />} />
          <Route path="/Page29" element={<Page29 />} />
          <Route path="/Page30" element={<Page30 />} />
          <Route path="/Page31" element={<Page31 />} />
          <Route path="/Page32" element={<Page32 />} />
          <Route path="/Page33" element={<Page33 />} />
          <Route path="/Page34" element={<Page34 />} />
          <Route path="/Page35" element={<Page35 />} />
          <Route path="/Page36" element={<Page36 />} />
          <Route path="/Page37" element={<Page37 />} />
          <Route path="/Page38" element={<Page38 />} />
          <Route path="/Page39" element={<Page39 />} />
          <Route path="/Page40" element={<Page40 />} />
          <Route path="/Page41" element={<Page41 />} />
          <Route path="/Page42" element={<Page42 />} />
          <Route path="/Page43" element={<Page43 />} />
          <Route path="/Page44" element={<Page44 />} />
          <Route path="/Page45" element={<Page45 />} />
          <Route path="/Page46" element={<Page46 />} />
          <Route path="/Page47" element={<Page47 />} />
          <Route path="/Page48" element={<Page48 />} />
          <Route path="/Page49" element={<Page49 />} />
          <Route path="/Page50" element={<Page50 />} />
          <Route path="/Page51" element={<Page51 />} />
          <Route path="/Page52" element={<Page52 />} />
          <Route path="/Page53" element={<Page53 />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
