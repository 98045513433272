import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./013.png";
import Page13_b from "./Page13_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page13() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
布施不是有錢人的專利，
<br />而是一分虔誠的愛心。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Bùshī bùshì yǒu qián rén de zhuānlì，
<br />ér shì yī fēn qiánchéng de àixīn.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
العطاء لا يقتصر على المال وحده، بل يمتد ليشمل الحب والإحسان أيضاً.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Bağış sadece parayla sınırlı değildir，içten gelen bir sevginin ifadesidir.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/MmrGHhpeC0o"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/9LXpmroQggA"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/LD2E0irg2fU"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
“لَنْ تَنَالُوا الْبِرَّ حَتَّى تُنْفِقُوا مِمَّا تُحِبُّونَ“ _ آية كريمة“
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
يبين الله عز وجل لعباده أنهم لن يصلوا إلى ثواب البر وهو الجنة، ولن يكونوا بررة يستحقون رضوان الله وفضله ورحمته، وصرف عذابه عنهم، حتى ينفقوا من أحب الأموال إليهم وأنفَسِهَا عندهم وأكرمها لديهم.
</p>
 <h5 className="turkish-text mt-4">
“Sevdiğiniz şeylerden feragat etmeden iyiliğe erişemezsiniz.“ _ Ayet-i Kerime
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
除非你們奉獻出你們喜愛的，否則你們絕對不會達到正義。
(古蘭經第三章92節)
</h5>
<p className="pinyin-text">
Chúfēi nǐmen fèngxiàn chū nǐmen xǐ'ài de, 
fǒuzé nǐmen juéduì bù huì dádào zhèngyì
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page13_b />
</section>
);
}
export default Page13;



