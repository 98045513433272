import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileALAAHEZAA from './alaa hezz.png';
function Page51_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
ALAAHEZAA:2008,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">ALAA HEZAA</strong>
</h1>
<h1 className="heading-name">
My age now: {ages.ALAAHEZAA} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileALAAHEZAA}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
在2017年11月，我們經過森林抵達土耳其。由於戰火侵襲了我們的家鄉，安全形勢急轉直下，我們被迫逃離。來到伊斯坦堡後不久，我們結識了慈濟基金會，在我們最困難的時候，這個組織伸出了援手，並持續幫助了我們將近三年。
在就讀土耳其學校時，我聽說過很多關於滿納海學校的事情，心中充滿嚮往，希望成為這個偉大家庭的一員並繼續學業。最終，我如願以償被錄取了。
出於對證嚴法師熱心助人的仰慕，我創作了一幅畫作，從她的言語中獲得了靈感。她的話語深深影響了我，讓我領悟到：人們不會在不經歷勞累、失敗與絕望的站點後便能抵達成功的花園，而擁有強大意志力的人不會在這些站點駐足太久。因此，即使在失敗後，只要願意重新站起，人們就不應悲傷。
那段穿越森林的旅程對我們全家來說是無比艱辛的，每一步都充滿了恐懼和不安。然而，當我們抵達伊斯坦堡並遇見慈濟基金會時，我們的生活開始有了轉機。他們無私的援助讓我們重新看到了希望，也讓我們體會到了人間的溫暖與關愛。
進入滿納海學校是我一直以來的夢想。這所學校對我來說不僅是學習知識的殿堂，更是一個充滿愛與支持的大家庭。每一天在這裡的學習經歷都讓我倍感珍惜，因為這是我努力追求夢想的起點。
證嚴法師的教誨深深激勵了我。她的話語不僅賦予了我力量，更讓我明白即使在最困難的時刻，也不要輕易放棄。她的智慧如同燈塔，指引著我前行的方向。創作這幅畫作的過程中，我用心捕捉每一個細節，試圖將內心的感動與對未來的希望融入其中。
這幅畫作，承載了我對證嚴法師的無限感激與敬仰。希望觀者能從中感受到那份深沉的情感，並從中獲得力量與啟發，無論面臨何種困境，都能堅定前行，追求自己的夢想。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
في شهر تشرين الثاني من عام 2017، وصلنا إلى تركيا عن طريق الغابات بعد دخول الحرب إلى منطقتنا وتردي الأحوال الأمنية فيها.
بعد فترة من وصولنا إلى إسطنبول تعرفنا على (مؤسسة تسجي)، كان وضعنا سيئاً فقامت المؤسسة بمساعدتنا بما يقارب ثلاث سنوات.
عندما كنت أدرس بالمدارس التركية سمعت عن (مدرسة المناهل) الكثير، أحببت أن أكون جزءً من هذه العائلة العظيمة وأكمل دراستي بها، فتم قبولي فيها.
قمت برسم لوحة مستوحاه من كلام (الماستر) بسبب حبها للخير والعطاء للآخرين.
أثرت كلماتها فيّ، فأدركت أن الناس لن يصلوا إلى حديقة النجاح، دون أن يمروا بمحطات التعب والفشل واليأس، وصاحب الإرادة القوية لا يطيل الوقوف في هذه المحطات.
فلا يحزن الإنسان إذا فشل مادام يحاول الوقوف على قدميه من جديد.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page51_b;
