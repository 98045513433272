import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileLOUAYKHARRAT from './LOUAY KHARRAT.png';
function Page36_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
LOUAYKHARRAT:2016,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">LOUAY KHARRAT</strong>
</h1>
<h1 className="heading-name">
My age now: {ages.LOUAYKHARRAT} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileLOUAYKHARRAT}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
2014年，由於戰爭和我們所經歷的艱難困境，炮火與炸彈四處肆虐，我的家人不得不離開家園，尋找一個更加安全穩定的地方。

我被安置在滿納海學校，在那裡學到了許多知識，也培養了許多良好的品德。

我創作了這幅畫，旨在表達對證嚴法師慈悲與寬容的感激之情。她在講述寬容與愛的時候，深深觸動了我的心靈，激勵著我們在困境中依然保持愛心與善意。






</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
وصلت عائلتي إلى تركيا عام 2014 بسبب الحروب والظروف الصعبة التي عشناها من القصف والقنابل ،غادرنا  بحثاً عن مكان أكثر أمنا واستقراراً.
وتم تسجيلي في (مدرسة المناهل) التي تعلمت منها الكثيرالعلوم واكتسبت فيها الكثير من الأخلاق الحسنة.
رسمت هذه الرسمة تعبيراً عن المحبة والعطاء والتسامح الذي قدمته الزعيمة في كلماتها عن التسامح والمحبة. 

</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page36_b;
