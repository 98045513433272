import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileNIZHAALAHMAD from './NIZHA AHMED.png';
function Page9_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
NIZHAALAHMAD:2008,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">NIZHA AL AHMAD</strong>
</h1>
<h1 className="heading-name">
My age now: {ages.NIZHAALAHMAD} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileNIZHAALAHMAD}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
2016年，我離開了被圍困和轟炸的城市，來到了土耳其。 
我到達伊斯坦堡時沒有在任何學校註冊，一想到無法完成學業我就感到絕望。 
但當我聽說  滿納海學校 開學時，情況發生了變化，我在那裡註冊並感到新的希望即將到來。 
我進入了二年級，發現學校是我可以開始新生活的地方。 
今天，將證嚴法師
 的的話變成一幅富有表現力的畫作，以表達我對慈濟和上人的感激之情。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
في عام 2016، وصلت إلى تركيا، بعد مغادرة مدينتي التي كانت تحت الحصار والقصف. وصلت إلى إسطنبول دون أن أكون مسجلة في أية مدرسة، وكنت يائسة من فكرة عدم إكمال دراستي. لكن ذلك تغير عندما سمعت عن افتتاح مدرسة المناهل الدولية، حيث سجلت وشعرت بأن هناك أمل جديد يلوح في الأفق. التحقت بالصف الثاني ووجدت في المدرسة مكانًا يمكنني من بدء حياة جديدة. في عيد تنصيب الزعيمة، قررت المشاركة بمشروع لتحويل كلماتها إلى لوحة تعبيرية كعربون شكر وامتنان.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page9_b;
