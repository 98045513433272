import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./04.png";
import Page4_b from "./Page4_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page4() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
會善用時間的人，時間如鑽石;
<br />不會善用時間的人，時間如泥土。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Huì shàn yòng shíjiān de rén，shíjiān rú zuànshí; 

<br />bù huì shàn yòng shíjiān de rén，shíjiān rú nítǔ.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
الوقت أغلى من الألماس لمن يعرف قيمته 
ولكنه عديم القيمة عند فاقدي الحكمة

</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Zaman，değerini bilenler için elmastan daha kıymetlidir; 
Ancak hikmetten yoksun olanlar için değersizdir.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/AaudxYRNwM8"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/dyYPYVv-Ct0"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/5tKhm8-35Mg"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
“يَا ابنَ آدَمَ إِنَّمَا أَنتَ أَيَّامٌ كُلَّمَا مَضَى يَومٌ ذَهَبَ بَعضُكَ.“ _ حديث موقوف“
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
عكس الحديث فكرة تأثير الزمن على حياة الإنسان وطبيعة الوجود. يمكن شرحها بالتالي:
حقيقة الحياة والموت: العبارة تشير إلى أن عمر الإنسان يتكون من مجموعة من الأيام، وكل يوم يمر يعني أن جزءًا من حياة الإنسان يتلاشى، هذا يذكّرنا بأن حياتنا قصيرة وأن كل لحظة هي قيّمة.
دعوة للتأمل: العبارة تدعو للتأمل في كيفية قضاء الوقت، إذا كان كل يوم يذهب جزء من حياتك، فهذا يحث على التفكير في كيفية استغلال كل يوم بشكل أفضل، لتحقيق الأهداف والاستمتاع باللحظات.
تقدير الوقت: بالفهم أن الأيام تمثل العمر، فإن هذه العبارة تدعو لتقدير الوقت وعدم التفريط فيه، فالوقت هو مورد محدود، ويجب استخدامه بحكمة.
التغيير والنمو: كما أن الأيام تمر، يتغير الإنسان وينمو، كل يوم يمر يجلب معه تجارب جديدة، وبالتالي يمكن أن يُنظر إلى “ذهاب بعضك“ أيضًا كتطور يتضمن الدروس المستفادة والنمو الشخصي.
الخاتمة والنهاية: تذكرنا العبارة بأن الموت هو أمر حتمي، وأن الحياة تتكون من لحظات يجب استغلالها بشكل يجلب السعادة والمعنى، مما يدفعنا للعمل في الاتجاه الذي نرغب فيه.
</p>
 <h5 className="turkish-text mt-4">
İnsanoğlu, yalnızca bir dizi günden ibarettir; her geçen günle biraz daha eksilir . _ Sahabe Sözü
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
人們啊，你有有限的生命它會一天一天的消失。
( 聖伴聖訓 )
</h5>
<p className="pinyin-text">
Rénmen a, nǐ yǒu yǒuxiàn de shēngmìng tā huì yītiān yītiān de xiāoshī
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page4_b />
</section>
);
}
export default Page4;



