import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./02.png";
import Page2_b from "./Page2_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page2() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
甘願做，
<br />歡喜受。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
gan yuan zuo，
<br />huan xi shou.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
اعمل بإخــلاص وتقبل النتائج بقلب مسـرور. 
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
İhlasla çalış ve sonuçları sevinçle kabul et.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/xHPh2gcG8oQ"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/qf8rUaMd_54"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/YE4k1OlYTy8"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
بقدر الكدّ تُكتســـب المعالي  ..  ومن طلب العلا سهر الليالي
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
لكل منّا طموحاته وأمانيه التي يسعى لتحقيقها، لكنها لا تتحقق بالتمني والأحلام، بل يحتاج ذلك إلى جدّ وعمل دؤوب متواصل لا يعترف بالكسل ولا بالملل، فالذين وصلوا إلى ما يسعون إليه بذلوا قصارى جهدهم، وواصلوا العمل ليل نهار، فالأمنيات لا تنقاد إلا للمثابرين الصابرين.
</p>
 <h5 className="turkish-text mt-4">
Yüksek mertebeler, çok çalışmakla elde edilir;
İlerleyecek olanlar ise geceleri uykusuz kalanlardır.
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
功夫不負有心人，求榮者夜不眠（阿拉伯諺語）
</h5>
<p className="pinyin-text">
Gōngfū bù fù yǒuxīnrén, qiú róng zhě yè bù. (Ālābó yànyǔ)
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page2_b />
</section>
);
}
export default Page2;



