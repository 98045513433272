import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileAMALIBRAHIM from './Amal ibrahim.png';
function Page53_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
AMALIBRAHIM:1986,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">AMAL IBRAHIM </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.AMALIBRAHIM} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileAMALIBRAHIM}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我來自敘利亞大馬士革，在美術學院學習，專攻視覺藝術（素描和繪畫）。
由於戰爭條件惡劣，我必須離開自己的國家到一個更安全的地方避難，所以我們別無選擇，只能選擇土耳其作為安全國家，我於2016年進入土耳其，住在伊斯坦堡蘇丹加濟。
我透過社群網站了解到滿納海學校，我很高興能與他們合作，但當我得知學校是由一個向世界各地的窮人和有需要的人伸出援手的慈善組織資助時，我的願望更加強烈。
是的，我很自豪自己是慈濟志工。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
أنا المتطوعة أمل إبراهيم
من سوريا مدينة دمشق درست في كلية الفنون الجميلة تخصص الفنون البصرية (الرسم والتلوين) .
بسبب ظروف الحرب وقساوتها اضررت أن أترك بلدي وألتجأ ألى مكان أكثر أمان فما كان منا إلا أن نختار تركيا كبلد آمن.
دخلت تركيا في عام 2016 سكنت في اسطنبول السلطان غازي. تعرفت إلى مدرسة المناهل من خلال مواقع التواصل الاجتماعي كنت متحمسة جدا للعمل معهم ولكن زادت رغبتي عندما علمت أن مدرسة المناهل ممولة من جمعية خيرية تمد يد العون للفقراء والمحتاجين في كل مكان.
نعم أنا وبكل فخر متطوعة في جمعية تسوجي الخيرية

</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page53_b;
