import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./043.png";
import Page43_b from "./Page43_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page43() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
自愛是報恩，付出是感恩。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Zì'ài shì bào'ēn，fùchū shì gǎn'ēn.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
الحب هو رد الجميل، والعطاء هو الامتنان.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Kendine saygı göstermek，şükran borcunu ödemektir; fedakârlık ise minnettarlığın ifadesidir.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/aKIxtejwiD8"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/zcKpkW7ZWuo"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/akRONyfnOdU"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
من يفعل الخـــــير لا يُعدم جوازِيَهُ  ..  لا يذهب العُرف بين الله والناس
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
لا بد أن تكون لك بصمة في هذه الحياة، ولا بد أن تترك أثرا طيبا فيها، ولا يكون ذلك إلا بفعل الخير وبذل المعروف، فالمعروف لا يضيع، وإن ضاع عند العباد فستجده مضاعفا عند ربهم، فافعل الخير ما استطعت، وكن واثقا من حسن العاقبة وروعة الجزاء، فلا يضيع معروف أينما زُرعا، ومن زرع قمحا لن يحصد شعيرا.
</p>
 <h5 className="turkish-text mt-4">
İyilik yapan kişi mükafatını bulur; iyilik ne Allah ne de insanlar arasında unutulur
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
行善的人不會失去真主的愛
(阿拉伯諺語)
</h5>
<p className="pinyin-text">
Xíngshàn de rén bù huì shīqù zhēnzhǔ de ài
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page43_b />
</section>
);
}
export default Page43;



