import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileHAIFAKAHWATIYE from './HAIFAA SHIKH ALKAHWATIE.png';
function Page11_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
HAIFAKAHWATIYE:2007,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">HAIFA KAHWATIYE </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.HAIFAKAHWATIYE} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileHAIFAKAHWATIYE}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我和家人於2016年抵達土耳其。
當我們到達土耳其時，生活狀況很困難。 
我父親曾經在車間工作。 
然後我母親開始在台灣義診中心擔任護士。 然後我們聽說有一所免費的阿拉伯語學校透過診所的員工教導敘利亞學生，那就是滿納海學校
我的父母急忙讓我和我的兄弟姊妹入讀這所學校。 我從六年級一直在那裡學習到現在十二年級。
證嚴法師和慈濟人在我們最困難的時候幫助我們
我透過畫這些畫來表達我們敘利亞人民對慈濟人的愛和感激之情，我們有責任回報那些多年來向我們伸出援手的人們。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
وصلنا أنا وعائلتي إلى تركيا عام 2016. خرجنا من حلب الى ادلب وجلسنا فيها لمدة 3 شهور ثم خرجنا الى اسطنبول عن طريق المعبر.
عند وصولنا إلى تركيا كان وضع المعيشة صعب.  كان والدي يعمل في الورشات. ثم بدأت والدتي بالعمل كممرضة في المستوصف التايواني. وبعدها سمعنا عن مدرسة عربية مجانية لتعليم الطلاب السوريين عن طريق الموظفين المتواجدين في المستوصف. كانت مدرسة المناهل الدولية سارع والداي لتسجيلي أنا وأخوتي في هذه المدرسة. درست فيها منذ الصف السادس الى الان الصف الثاني عشر.
كان ولا يزال دعم الزعيمة وشعب تايوان لنا في كل أوقاتنا الصعبة مستمراً. وشعرت أن أبسط شيء بإمكاننا أن نقدمه لهم أن نرسم هذه اللوحات تعبيرا عن حبنا وامتنانا لمعروفهم مع الشعب السوري وأن من واجبنا رد الجميل لمن مد يد العون لنا طيلة هذه السنوات.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page11_b;
