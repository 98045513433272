import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileAYALAILA from './AYA LAILA.png';
function Page50_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
AYALAILA:2010,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">AYA LAILA</strong>
</h1>
<h1 className="heading-name">
My age now: {ages.AYALAILA} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileAYALAILA}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我們於2020年從家鄉逃離，踏上了前往土耳其的非法移民之路。這段旅程充滿了無數的危險，我們曾數次與死神擦肩而過，也遭受了許多身心上的折磨。經過了五次的嘗試，我們終於成功抵達了土耳其。

在土耳其生活了兩年後，我們才得知了滿納海國際學校。由於語言不通，我們起初很難找到穩定的工作。長時間的勞動和微薄的薪資，讓我們的生活過得相當困苦，甚至只能睡在地板上。

後來，我在網路上發現了滿納海學校，並讓孩子進入六年級就讀。

這世上最偉大的愛，莫過於父母的關愛和師長的照顧。滿納海學校的師長們對我們這些來自敘利亞的學生關懷備至，這一切都要感謝證嚴法師的慈愛。她為學校提供了無私的支持，讓我們這些遠離家鄉的孩子，能夠在異國他鄉繼續求學。我由衷地感謝證嚴法師，也感謝滿納海學校的所有老師，謝謝你們讓我能夠克服語言障礙，在這個陌生的國度裡繼續學習。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
جئنا إلى تركيا من عام 2020 بهجرة غير شرعية. وكان الطريق خطراً جداً تعرضنا للموت عدة مرات. وتعرضنا للأذى حاولنا العبور عدة مرات ونجحنا في المحاولة الخامسة.
تعرفنا على مدرسة المناهل الدولية بعد عامين من دخولنا إلى تركيا.
لم نجد عمل فورا لبعد عدة أشهر بسبب اللغة وكان العمل لا يقل عن ١٣ ساعة وراتب قليل الوضع المعيشي اقل من المتوسط لدرجة أننا ننام على فراش في الأرض.
تعرفت على المدرسة المناهل عبر الانترنت الصف الذي سجلت به السادس.
أعظم شيء في الدنيا هو حب الوالدين ورضاهم ورعاية المعلمين والمشرفين والإدارة الكريمة. والعناية بالطلاب السوريين برعاية من الزعيمة التي أحاطت المدرسة بعطفها وحنانها وتحملت متطلبات المدرسة والطلاب من جميع الجوانب التي تحتاجها تسجي واشكر الزعيمة الفاضلة من كل جوارحي واتمنى لها كل الخير لأنها اتاحت لي اكمال دراستي في هذه البلد الغريبة عني من ناحية اللغة واشكر المعلمين على فضلهم.

</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page50_b;
