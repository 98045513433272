import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileALAAALDABAK from './Alaa aldabak.png';
function Page6_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
ALAAALDABAK:1983,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name"> ALAA AL DABAK </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.ALAAALDABAK} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileALAAALDABAK}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我來到土耳其是因為2016年的戰爭，我和我的父母來到這。
在到達土耳其三個月之後，我來到伊斯坦堡，到我哥哥家，他為我找到一份合適的工作。
我開始尋找一份適合我的工作，並在 Facebook 上找到一則關於蘇丹加濟地區 滿納海學校電腦教師職業的廣告。
當時，我對學校、基金會甚至這個地區一無所知。
我來到學校，向行政部門提交了我的檔案，經過考試和面試，我被學校錄取了。
當時我非常高興，這是我在滿納海學校和慈濟基金會開始志工服務的地方，我從上班的第一天起就開始做志工，為前來基金會尋求幫助的人提供協助當有被幫助的人說我很好運和幸福時，我感謝真主因為我真的感到幸福。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
أتيت إلى تركيا بسبب الحرب عام ٢٠١٦ مع امي وابي.
بعد معاناة محاولة الوصول إلى تركيا لمدة ثلاث أشهر.
أتيت إلى اسطنبول إلى بيت اخي لغاية إيجاد عمل مناسب لي.
وبدأت بالبحث عن عمل يناسبني وقرأت اعلان في الفيسبوك عن مهنة معلم حاسوب في مدرسة المناهل بمنطقة السلطات غازي. حينها لم أكن أعرف اي شيء عن المدرسة ولا الجمعية ولا حتى المنطقة. 
أتيت إلى العنوان وقدمت ملفي للإدارة وبعد فحص ومقابلات تم قبولي في المدرسة. 
وكانت فرحتي عارمة حين ذلك. 
وهنا بدأت رحلتي التطوعية في مدرسة المناهل ومع مؤسسة تسجي الخيرية، وبدأت التطوع منذ الأيام الأولى لي في المدرسة في تقديم المساعدة للآخرين الذين يأتون إلى الجمعية طلبا للمساعدة.
 شعور السعادة والفرح يغمرني عندما يدعو لي أحد المحتاجين بالتوفيق والسعادة.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page6_b;
