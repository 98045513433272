import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillGithub,
  AiFillYoutube,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright">
          <h3>These are our creativity</h3>
        </Col>
        <Col md="4" className="footer-copywright">
          <h3>Copyright © {year} TZUCHI turkiye IT office</h3>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
          <li className="social-icons">
  <a
    href="https://youtube.com/@tzuchivakfi?si=h4x0ElHRcQjoYtRK"
    style={{ color: "white" }}
    target="_blank" 
    rel="noopener noreferrer"
      >
        <AiFillYoutube /> {/* استبدال FaLinkedinIn بـ AiFillYoutube */}
      </a>
    </li>
            <li className="social-icons">
              <a
                href="https://www.instagram.com/tzuchivakfi?igsh=M3J6dTFjNWJnN20z"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillInstagram />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
