import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileYARAKAHWATIYE from './YARA KAHWATIYE.png';
function Page21_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
YARAKAHWATIYE:2008,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">YARA KAHWATIYE </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.YARAKAHWATIYE} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileYARAKAHWATIYE}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
在這段艱難而漫長的旅程中，我的父親比我們先一步出發。
他甚至在渡河進入土耳其時掉進了河裡。
兩年後，我們也來到了土耳其，經歷了一次將近兩個半月的艱辛旅程。
到達土耳其後，我們暫時住在父親一個朋友家裡。然後，我們找到了一個原本是垃圾場和流浪貓棲息地的地下室。我們清理了垃圾，把它打掃乾淨，並在裡面住了六個月。那時正值冬季，每次下雨，雨水都會淹滿整個房子。
我的母親在慈濟基金會的診所裡擔任護士和志工，而我的父親則從事木工工作，直到我們的生活條件稍有改善，能夠搬到一個更好的住所。
當滿納海學校開辦時，我從五年級就開始在那裡學習，直到現在。
我從慈濟基金會學到了很多東西，因此和我的妹妹一起在那裡做志工
證嚴法師的靜思語體現了每個人心中都應該有人性，無論年齡大小都應該存在於每一個人的心中。
靜思語讓我想起了我們伊斯蘭教中的類似教誨和格言，這也是我將這些話轉化為一幅充滿生機的畫作的原因。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
سبقنا والدي في رحلة اللجوء المتعبة والصعبة حتى أنه سقط في النهر أثناء عبوره إلى تركيا، ثم أتينا إلى تركيا بعده بسنتين في رحلة صعبة وطويلة استغرقت ما يقارب من الشهرين والنصف.
عندما وصلنا تركيا أقمنا عند صديق أبي فترة قصيرة، ثم وجدنا قبواً كان مكباً للقمامة ومأوى للقطط، أفرغنا القمامة ونظفناه وبقينا فيه مدة 6 أشهر، كانت الوقت شتاءً، وكان ماء المطر يملأ البيت عندما يهطل في كل مرة. 
عملت أمي ممرضة ومتطوعة في مستوصف (تسجي) وعمل أبي نجارًا إلى أن تحسن وضعنا قليلاً واستطعنا أن ننتقل إلى منزل أفضل. 
وعندما تم افتتاح (مدرسة المناهل) تم تسجيلي فيها من الصف الخامس وحتى الآن. 
تعلمت من (مؤسسة تسجي) الكثير فلذلك تطوعت مع أختي فيها. 
كلام (الماستر) دليل على الإنسانية التي يجب أن تكون في قلوب الجميع، في قلب كل صغير وكبير، ولأنها تذكرني بأقوال وحكم ديننا الإسلامي المشابهة له، هذا الذي كان سببا لأنقل كلامها إلى لوحة تنبض بالحياة..
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page21_b;
