import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./040.png";
import Page40_b from "./Page40_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page40() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
真正的孝順，只是聽話還不夠， 
<br />必須和顏悅色，令父母歡喜。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Zhēnzhèng de xiàoshùn， zhǐshì tīnghuà hái bùgòu， 

<br />bìxū héyányuèsè，lìng fùmǔ huānxǐ.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
كونك ابنا صالحاً أو ابنة صالحة لا يعني فقط الاستماع إلى والديك 
ولكن أيضاً أن تكون لطيفاً معهم لإسعادهم.

</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
İyi bir evlat olmak，sadece ebeveynlerini dinlemek değil，aynı zamanda onlara nazik davranarak onları mutlu etmek demektir.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/5EY10Xk5pyo"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/nh0AbDvqaUY"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={4} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="180"
height="150"
src="https://www.youtube.com/embed/hJS4tTdArFA"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
وَوَصَّيْنَا ٱلْإِنسَانَ بِوَٰلِدَيْهِ حُسْنًا.“ _ آية كريمة"
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
وصى الله عز وجل الإنسان بوالديه أكثر من مرة في القرآن الكريم وذلك دليلاً على أهمية الإحسان إليهما وبرهما في الحياة وبعد الممات، وذلك بالحنو عليهما والإنفاق عليهما عند الحاجة والبشاشة عند لقائهما، وأما بعد موتهما فبالدعاء والاستغفار لهما، وإنفاذ عهدهما من بعدهما، وصلة الرحم التي لا توصل إلا بهما، وإكرام صديقهما.
</p>
 <h5 className="turkish-text mt-4">
“İnsana, anne ve babasına iyi davranması öğütlenmiştir.“ _  Ayet-i Kerime
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
我已命人當孝敬父母
（古蘭經第29章第八節）
</h5>
<p className="pinyin-text">
Wǒ yǐ mìng rén dāng xiàojìng fùmǔ
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page40_b />
</section>
);
}
export default Page40;



