import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileRADIYABADENJKİ from './RADIYA BADENJKI.png';
function Page18_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
RADIYABADENJKİ:2015,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">RADIYA BADENJKİ</strong>
</h1>
<h1 className="heading-name">
My age now: {ages.RADIYABADENJKİ} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileRADIYABADENJKİ}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我的家人在2012年來到土耳其，這裡成為了我們安全的避風港，溫暖的天空，以及充滿希望的未來。

我在伊斯坦堡出生並成長，五歲時便進入了滿納海學校，我感到非常幸福。

事實上，當今社會缺乏許多人性與真誠的價值，而我們需要不斷加強這些品質。證嚴法師的話語不僅美麗動人，更是指引我們走向正道的明燈。受此啟發，我創作了一幅畫，希望這些話語能深深植根於我們的心靈，成為我們內心的一部分。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
قدمت عائلتي إلى تركيا عام 2012 كانت تركيا ملجأ آمناً وسماءا دافئة ومستقبلاً مشرقاً.
خلقت في إسطنبول وترعرعت فيها ودخلت مبكراً إلى مدرسة المناهل بعمر خمس سنوات وكنت سعيدة جداً.
في الحقيقة هناك الكثير من الإنسانية والصدق التي نفتقدها في وقتنا الحالي، ونحتاج إلى تعزيزها، فكانت عبارة الزعيمة أكثر من روعة، فالكلمات الطيبة والنوايا الحسنة تقودنا إلى الطريق المستقيم فمن خلالها عبّرت برسمة عسى أن تترسخ هذه الكلمات في جوارحنا.

</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page18_b;
