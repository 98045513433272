import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileSIDRAALMUSA from './SIDRA AL MUSA.png';
function Page7_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
SIDRAALMUSA:2016,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">SIDRA AL MUSA</strong>
</h1>
<h1 className="heading-name">
My age now: {ages.SIDRAALMUSA} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileSIDRAALMUSA}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我於2016年以非法途徑抵達土耳其，這是一段充滿危險的旅程，我們抵達伊斯坦堡。
到這裡後我們遇到慈濟基金會，他們是善良且慷慨的人，我們感到很幸運，他們提供了一些食物幫助我們，我們對他們深表感謝。
在我們安頓下來後，找到了一個住所，我開始在一個裁縫工廠工作了三年，以賺取家庭的開支。
後來，我母親為了我的學業，向慈濟基金會提出了申請。
我熱愛學習，希望建立一個美好的未來，成為一個堅強、成功和有教養的女性。
我的申請被接受了，他們每月給予我一筆津貼。
我辭去了工作，開始在滿納海學校學習，我成為了優秀的學生之一。
證嚴法師喚醒了我內心對知識的渴望，我喜歡她的教學風格，她能夠清晰地傳遞好的思想給學生們。
我希望展示出勇氣和耐心，在學術上建立一個美好的未來。
她是我的榜樣，我從她的講話中獲得靈感，她的話語深深地印在我的心靈深處，所有的學生都喜歡她。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
وصلت إلى تركيا 2016 بطريقة غير شرعية بعد عدة محاولات، كانت رحلة مليئة بالمخاطر، قدمنا إلى ولاية إسطنبول، كانت رحلة صعبة لكنها جميلة عندما وجدنا أناس جيدين ورحيمين مثل مؤسسة تسجي.  التي قامت بمساعدتنا ببعض المواد الغذائية، إننا ممتنون لهم.
بعدما استقر حالنا وجدنا منزلاً يأوينا بدأت العمل في ورشة خياطة لمدة ثلاث سنوات لأساعد عائلتي بمصروف المنزل، ثم قدمت أمي طلباً لمؤسسة تسجي للمساعدة بشأن دراستي لأنني أحب الدراسة وأريد أن ابني مستقبلاً جميلاً، أريد أن أصبح امرأة قوية وناجحة ومثقفة، تم قبول الطلب وخصصوا لي مبلغاً شهرياً، تركت العمل وبدأت بالدراسة في مدرسة المناهل وكنت من الطلاب المتفوقين. 
لقد أثارت  الماستر  القوة المكمونة في داخلي تجاه العلم وأحببت أسلوبها بتوصيل الأفكار الجيدة للطلاب، أردت أن أظهر مشاعر الشجاعة والصبر في العلم لبناء مستقبل جميل، أردت توصيل فكرة أن القوة في الإرادة والإرادة في العمل والعمل يريد الشجاعة والصمود والإلهام. 
 الماستر  هي قدوتي إنني أستلهم أفكاري وأقوي عزيمتي عندما استمع لحديثها، كلامها يحفّر في وسط العقل والقلب، أسلوبها جميل جداً وكل الطلاب يحبونها.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page7_b;
