import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileZAYNAPALHAWRAALABOO from './ZEINAB ALHAWRAA ALABBO.png';
function Page15_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
ZAYNAPALHAWRAALABOO:2008,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">ZAYNAP ALHAWRA ALABOO </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.ZAYNAPALHAWRAALABOO} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileZAYNAPALHAWRAALABOO}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
2016年，我離開敘利亞哈馬省，前往土耳其邊境。 
我在邊境等了一整天，才進入土耳其。 
抵達後，我與家人共五口人定居在伊斯坦堡。 剛開始生活時，我們的房子完全沒有家具，但我們認識了 慈濟基金會
和 滿納海學校，他們透過 BIM 卡為我們提供了支持。 我是在小學二年級入學的。 
我選擇這句話是因為寬容使人與人之間的關係更加溫暖，愛與幸福得以延續，我意識到善意的話語可以為心靈帶來幸福。
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
في عام 2016، غادرت محافظة حماة في سوريا، حيث كنا لاجئين، واتجهت نحو الحدود التركية. انتظرت على الحدود ليوم كامل قبل أن أدخل تركيا بطريقة غير نظامية. بعد الوصول، استقريت في إسطنبول مع عائلتي المكونة من خمسة أفراد. في بداية حياتنا هناك، كان منزلنا فارغًا تمامًا من الأثاث، لكننا تعرفنا على مؤسسة تسجيو مدرسة المناهل التي قدمت لنا الدعم من خلال كروت البيم. التحقت بالمدرسة في الصف الثاني الابتدائي. اخترت تلك العبارة لأن التسامح يجعل العلاقات أكثر دفئًا ويطيل عمر المحبة والسعادة، وأدركت أن الكلام الطيب يجلب السعادة للقلوب.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page15_b;
