import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import profileMARAMALZIR from './MARAM ALZIR.png';
function Page35_b() {
const [ages, setAges] = useState({});

useEffect(() => {
const birthYears = {
MARAMALZIR:2010,
};
const currentYear = new Date().getFullYear();
const calculatedAges = Object.keys(birthYears).reduce((acc, person) => {
 acc[person] = currentYear - birthYears[person];
 return acc;
}, {});
setAges(calculatedAges);
}, []);
return (
<section>
<Container fluid className="home-section"  >
<h1 id="about-section" className="project-heading" data-aos="flip-down">
 Let me introduce you<strong className="purple"> about myself</strong>
</h1>
<Container className="home-content">
 <Row className="justify-content-center align-items-center" style={{ minHeight: '2vh' }}>
<Col md={5} className="home-header" data-aos="fade-right">
<div className="bg-yellow-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
<h1 style={{ paddingBottom: 5 }} className="heading">
Hi I'M <strong className="main-name">MARAM ALZIR </strong>
</h1>
<h1 className="heading-name">
My age now: {ages.MARAMALZIR} years old
</h1>
<div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</Col>
<Col md={3} className="d-flex justify-content-center align-items-center" data-aos="fade-left">
<div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
 <div style={{ background: "#23BDEE" }} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
<img
src={profileMARAMALZIR}
alt="home pic"
className="img-fluid"
style={{
boxShadow: '0 4px 10px rgba(0, 128, 0, 0.6)',
 width: '200px',
height: '200px',
objectFit: 'cover',
 borderRadius: '10px'
}}
/>
 <div className="bg-yellow-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
</div>
</Col>
</Row>
</Container>
</Container>
<h1 id="story-section" className="project-heading" data-aos="flip-down">
This is my <strong className="purple">Story</strong>
</h1>
<Container fluid className="Story-section">
<Container>
<Row className="justify-content-center">
<Col md={4} className="text-left" data-aos="fade-right">
<p className="chinese-text">
我的生活充滿了挑戰，從我們在敘利亞的家被轟炸，到我們在杜拜的苦難，直到我父親被驅逐到伊斯坦堡。 
2016年，我們終於來到了土耳其，在那裡我們沒有辦法接受教育，面臨著生活上巨大的困難。 
機緣巧合，在慈濟診所的幫助下，我找到了去滿納海學校繼續學業的途徑。
這一幅畫用來表達我對慈濟的感恩
</p>
</Col>
<Col md={4} className="text-right" data-aos="fade-left">
<p className="arabic-text">
عشت حياة مليئة بالتحديات، من قصف بيتنا في سوريا ومرورًا بمعاناتنا في دبي وحتى ترحيل والدي إلى إسطنبول. في عام 2016، وصلنا إلى تركيا بعد معاناة طويلة، حيث كنا نعيش دون تعليم ونواجه صعوبات جمّة. عبر الصدفة وبمساعدة المستوصف التيواني، وجدت طريقي إلى مدرسة المناهل حيث تمكنت من استكمال تعليمي. الفضل يعود للأشخاص الذين ساندونا، والذين وجدت في كلماتهم الدافئة إلهامًا دفعني لرسم لوحة تعبر عن امتناني وتقديري لهم.
</p>
</Col>
</Row>
 </Container>
 </Container>
</section>
);
}
export default Page35_b;
